import React, { Component, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { wp } from "../api/WpApi";
import { PostBadge } from "../alerts/PostBadge";
import "../../styles/wpStyles.scss";
import WpService from "./WpService";
import { ItemListSchema } from "../seo/ItemListSchema";

import data from "./_setup.json";

import PageLoading from "./pageLoading";
import { Footer } from "../main/Footer";
import MetaTags from "../seo/MetaTags";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

// Date Format: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString

const PostList = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [postsperpage, setPostsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(1);
  const [totalpages, setTotalPages] = useState(null);
  const [totalCategories, setTotalCategories] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState([]);

  var itemListArray = [];
  var listCount = 1;

  const readTime = (content) => {
    const wordsPerMinute = 300; // Average case.
    let result;

    let textLength = content.split(" ").length; // Split by words
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute);
      result = `~${value} min. read`;
    }
    return result;
  };

  const loadMore = () => {
    setCurrentPage((prev) => prev + 1);
    getPosts(currentpage, null);
  };

  useEffect(() => {
    loadMore();
    getCategories();
  }, []);

  const getCategories = () => {
    // Fetch pages with search value "xyz": http://wp-api.org/node-wpapi/using-the-client/#api-query-parameters
    wp.categories()
      .perPage(100)
      .then((categories) => {
        if (categories != null) {
          setTotalCategories(categories);
        }
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  const handleRadioChange = (e) => {
    let checkedRadio = selectedRadio;

    e.target.checked
      ? checkedRadio.push(e.target.id.toString())
      : (checkedRadio = checkedRadio.filter(
          (f) => f !== e.target.id.toString()
        ));
    setSelectedRadio(checkedRadio);
    getPosts(1, checkedRadio);
  };

  const cbChange = (e) => {
    var cbs = [];
    cbs.target = document.getElementsByClassName("cb");
    for (var i = 0; i < cbs.target.length; i++) {
      cbs.target[i].checked = false;
    }
    e.target.checked = false;
    setSelectedRadio([]);
    setIsLoading(true);
    getPosts(1, null);
  };

  const getPosts = (currPage, categoryId) => {
    // Fetch posts: http://wp-api.org/node-wpapi/using-the-client/#api-query-parameters
    wp.posts()
      .categories(categoryId)
      // .param("before", new Date("2016-09-22")) // Sitaip galima filtrus det. Leidzia NULLL TODO: CIAIAIAISADIJASID
      // .param("category", this.state.selectedRadio)
      .perPage(postsperpage)
      .page(currPage)
      .embed()
      .then((posts) => {
        //console.log(posts);

        // Append post data to existing posts
        if (currPage === 1) {
          setPosts(posts);
          setTotalPages(posts._paging.totalPages);
          setIsLoading(false);
        } else {
          setPosts((prevPosts) => prevPosts.concat(posts));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  if (error) {
    return <div className="content">Error: {error.message}</div>;
  } else if (isLoading) {
    return (
      <div className="container">
        <PageLoading />
      </div>
    );
  } else if (posts != []) {
    return (
      <div className="content container">
        {/* META TAGS FOR POST LIST */}
        <MetaTags
          name="description"
          content="Here you can find Every Single Andrew's Cookin' Recipe from Videos and Even More!"
        ></MetaTags>
        <MetaTags name="og_locale" content="en_US"></MetaTags>
        <MetaTags name="og_url" content={window.location.href}></MetaTags>
        <MetaTags
          name="og_site_name	"
          content="Andrew's Cookin' Recipes"
        ></MetaTags>
        <Helmet>
          <link
            rel="canonical"
            href={"https://www.andrewscookin.com/" + data.url.slug_posts}
          ></link>
        </Helmet>
        <div className="row">
          <div className="col-md"></div>
          <div className="col-md-10">
            <h1 className="fw-bold">All the Recipes</h1>
            <small className="fw-light">
              as seen on{" "}
              <span className="text-muted">Andrew's Cookin' YouTube</span> &
              more
            </small>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md">
            Filter Recipes:
            <hr />
            {/* Tailwind Filters */}
            {/* <ul class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200">
              <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                <div
                  className="form-check flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                  key="default-category"
                >
                  <input
                    key="default-category"
                    className="form-check-input w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    type="checkbox"
                    name="checkBox-default"
                    id="default"
                    value="Default"
                    onChange={cbChange}
                    style={{ cursor: "pointer" }}
                  ></input>
                  <label
                    key="default"
                    className="form-check-label"
                    htmlFor="default"
                    style={{ cursor: "pointer" }}
                  >
                    All Posts
                  </label>
                </div>
              </li>
              {totalCategories != null &&
                totalCategories.map((category) => (
                  <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                    <div
                      className="form-check flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                      key={category.id + "-category"}
                    >
                      <input
                        key={category.id + "-category"}
                        className="form-check-input cb"
                        type="checkbox"
                        name={"checkBox-" + category.id}
                        id={category.id}
                        value={category.id}
                        onChange={handleRadioChange}
                        style={{ cursor: "pointer" }}
                      ></input>
                      <label
                        key={category.id}
                        className="form-check-label transition ease-in-out delay-50 duration-300"
                        htmlFor={category.id}
                        style={{ cursor: "pointer" }}
                      >
                        {category.name}
                      </label>
                    </div>
                  </li>
                ))}
            </ul> */}
            <div className="list-group-horizontal-md align-items-start filter-list-group">
              <div className="form-check" key="default-category">
                <input
                  key="default-category"
                  className="form-check-input"
                  type="checkbox"
                  name="checkBox-default"
                  id="default"
                  value="Default"
                  onChange={cbChange}
                  style={{ cursor: "pointer" }}
                ></input>
                <label
                  key="default"
                  className="form-check-label"
                  htmlFor="default"
                  style={{ cursor: "pointer" }}
                >
                  All Posts
                </label>
              </div>
              {totalCategories != null &&
                totalCategories.map((category) => (
                  <div className="form-check" key={category.id + "-category"}>
                    <input
                      key={category.id + "-category"}
                      className="form-check-input cb"
                      type="checkbox"
                      name={"checkBox-" + category.id}
                      id={category.id}
                      value={category.id}
                      // checked={selectedRadio[category.id] || false}
                      // defaultChecked={
                      //   selectedRadio.includes(category.id) ? true : false
                      // }
                      onChange={handleRadioChange}
                      style={{ cursor: "pointer" }}
                    ></input>
                    <label
                      key={category.id}
                      className="form-check-label transition ease-in-out delay-50 duration-300"
                      htmlFor={category.id}
                      style={{ cursor: "pointer" }}
                    >
                      {category.name}
                    </label>
                    {/* <Helmet>{parse(this.state.totalCategories[0].yoast_head)}</Helmet> */}
                  </div>
                ))}
            </div>
            <hr />
          </div>
          <div className="list-group col-lg-10">
            {posts.map(
              (post) => (
                itemListArray.push({
                  ["@type"]: "ListItem",
                  position: listCount++,
                  url: post.link, //this has to have the slug. it's in WP settings->permalinks
                }),
                (
                  <Link
                    key={post.id}
                    to={post.slug}
                    className="shadow-sm list-group-item list-group-item-action transition ease-in-out delay-50 duration-300"
                  >
                    {(post.class_list.includes("category-recipes") ||
                      post.class_list.includes("category-recipe")) && (
                      <PostBadge
                        text="Recipe"
                        style="absolute top-6 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20"
                        color="success"
                      ></PostBadge>
                    )}
                    <h2
                      style={{ padding: 20 }}
                      dangerouslySetInnerHTML={{
                        __html: post.title.rendered,
                      }}
                    />

                    <div className="post-category">
                      Categories:
                      <div>
                        {post.class_list !== null &&
                          WpService.getItemFromClassList(
                            post.class_list,
                            "category-"
                          ).map((item) => (
                            <PostBadge
                              key={item}
                              text={item}
                              style="child-category"
                              color="secondary"
                            ></PostBadge>
                          ))}
                      </div>
                    </div>
                    {post._embedded["wp:featuredmedia"] !== undefined &&
                      post._embedded["wp:featuredmedia"] && (
                        <div className="post-thumbnail">
                          <img
                            src={
                              post._embedded["wp:featuredmedia"][0].source_url
                            }
                            alt={post._embedded["wp:featuredmedia"][0].alt_text}
                          ></img>
                        </div>
                      )}
                    <p
                      className="excerpt-limit"
                      dangerouslySetInnerHTML={{
                        __html: post.excerpt.rendered,
                      }}
                    />
                    <div className="post-date absolute right-6 -translate-x-1/6 z-20">
                      <small>
                        {new Date(post.date).toLocaleDateString(
                          data.format.date.locale,
                          data.format.date.options
                        )}
                      </small>
                    </div>
                    <p className="post-read-time font-weight-light">
                      <u>{readTime(post.content.rendered)}</u>
                    </p>
                  </Link>
                )
              )
            )}
            <Helmet>
              <script type="application/ld+json">
                {JSON.stringify(ItemListSchema(itemListArray))}
              </script>
            </Helmet>
          </div>
        </div>
        {currentpage <= totalpages && (
          <div className="row">
            <div className="col-md"></div>
            <div className="col-md-10">
              <button
                onClick={loadMore}
                type="button"
                className="btn btn-primary d-block my-5 mx-auto"
              >
                Load more
              </button>
            </div>
          </div>
        )}
        <hr />
        <Footer></Footer>
      </div>
    );
  } else {
    return <div className="content">Nothing to View!</div>;
  }
};

export default PostList;
