import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import UserService from "../users/service/UserService";

class UnlinkBarboraDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rememberMe: true,
      barboraEmail: "",
      barboraPassword: "",
      data: [],
      isDataLoading: false,
      show: false,
    };
    this.click = this.click.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  fieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  click() {
    this.setState({ isDataLoading: true });
    UserService.disconnectUserFromBarbora(this.props.userData.id, this.state)
      .then((response) => {
        this.setState({ data: response.data, isDataLoading: false });
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ data: err, isDataLoading: false });
      });
  }

  render() {
    return (
      <span>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Unlink your "Barbora" account...</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={(this.handleClose, this.click)}>
              Disable "Barbora" account
            </Button>
          </Modal.Footer>
        </Modal>

        <Button variant="outline-danger" onClick={this.handleShow}>
          Unlink "Barbora" account
        </Button>
      </span>
    );
  }
}
export default UnlinkBarboraDetails;
