import React from "react";
import { v4 } from "uuid";
import ListGroup from "react-bootstrap/ListGroup";
import i18n from "../../i18n";
import ApiService from "../api/ApiService";
import UtilsService from "../users/service/UtilsService";

function convertToMetric(amount, unit) {
  let newAmount = null;
  let newUnit = null;

  unit.toLowerCase();

  if (unit === "pound" || unit === "pounds") {
    newAmount = roundNumber(amount / 2.2046);
    console.log("newamount " + newAmount);
    if (newAmount > 1) {
      newAmount = newAmount.toString();
      newUnit = "kg";
    } else {
      newAmount = (newAmount * 1000).toString();
      newUnit = "g";
    }
  } else if (unit === "ounce" || unit === "ounces") {
    newAmount = amount * 0.028349523 * 1000;
    if (newAmount > 1000) {
      newAmount = roundNumber(newAmount / 1000).toString();
      newUnit = "kg";
    } else {
      newAmount = roundNumber(newAmount).toString();
      newUnit = "g";
    }
  } else if (unit === "inch" || unit === "inches") {
    newAmount = roundNumber(amount * 2.54).toString();
    newUnit = "cm";
  }

  if (newAmount !== null) {
    let formatedResult = " (~ " + newAmount + " " + newUnit + ")";
    return formatedResult;
  }

  return "";
}

function roundNumber(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

const RecipeIngredients = ({ ingredients, isExistingProduct }) => {
  return ingredients.map((ingredient) => {
    return (
      <>
        {/* <div className={"btn-group pull-right " + (this.props.showBulkActions ? 'show' : 'hidden')}></div> */}
        <div className={"font-medium"}>
          {UtilsService.Capitalize(ingredient.name)}
        </div>
        <div
          style={
            !isExistingProduct
              ? ({ textShadow: "0 0 5px red" }, { color: "red" })
              : { color: "green" }
          }
        >
          <div className={"font-bold"}>
            {roundNumber(ingredient.amount)} {ingredient.unitLong}
          </div>
          <div className={"font-bold"}>
            {convertToMetric(ingredient.amount, ingredient.unitLong)}
          </div>
        </div>
      </>
    );
  });
};

export default RecipeIngredients;
