import {
  authorizationRequest,
  authorizationSuccess,
  authorizationFail,
} from "./types";

export const authenticate = () => {
  return {
    type: authorizationRequest,
  };
};

export const authSuccess = (content) => {
  localStorage.setItem("USER_TOKEN", content.token);
  return {
    type: authorizationSuccess,
    payload: content,
  };
};

export const authFailure = (error) => {
  return {
    type: authorizationFail,
    payload: error,
  };
};
