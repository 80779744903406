import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import FridgeTableComponent from "./FridgeTableComponent";
import FridgeService from "./service/FridgeService";
import AddFoodToFridgeComponent from "./AddFoodToFridgeComponent";
import i18n from "./../../i18n";

class CreateFridge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      size: "50",
      foods: [],
      fridges: [],
      response: "",
      data: [],
      isDataLoading: false,
      show: false,
    };
    this.click = this.click.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  fieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  click() {
    this.setState({ isDataLoading: true });
    FridgeService.postNewFridge(this.props.fridgeData.id, this.state)
      .then((response) => {
        this.setState({ data: response.data, isDataLoading: false });
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ data: err, isDataLoading: false });
      });
  }

  render() {
    return (
      <span>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t("createFridge_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>{i18n.t("createFridge_name")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={i18n.t("createFridge_name_placeholder")}
                  name="name"
                  value={this.state.name}
                  onChange={this.fieldChange}
                  autoFocus
                />
              </Form.Group>
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>
                  {i18n.t("createFridge_size")}
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="69"
                  name="size"
                  value={this.state.size}
                  onChange={this.fieldChange}
                />
              </Form.Group> */}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              {i18n.t("createFridge_button_close")}
            </Button>
            <Button variant="primary" onClick={(this.handleClose, this.click)}>
              {i18n.t("createFridge_button_confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
        {!this.props.fridgeData.fridge && (
          <Button variant="outline-success" onClick={this.handleShow}>
            {i18n.t("createFridge_action_button")}
          </Button>
        )}
        {this.props.fridgeData.fridge && (
          <FridgeTableComponent userData={this.props.fridgeData} />
        )}
        {this.props.fridgeData.fridge &&
          this.props.fridgeData.fridge.foods <= 0 && (
            <AddFoodToFridgeComponent
              userFridge={this.props.fridgeData.fridge}
            />
          )}
      </span>
    );
  }
}
export default CreateFridge;
