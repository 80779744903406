import data from "../wp/_setup.json";

const WPAPI = require("wpapi");

const wp = new WPAPI({
  // http://wp-api.org/node-wpapi/using-the-client
  endpoint: process.env.REACT_APP_WP_API_IP,
});

export { wp };
