import { useState } from "react";
import { sanitize } from "./miscellaneous";
// import Loading from "../../loading";
import i18n from "../../../i18n";

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [text, setText] = useState("");

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Įveskite el. paštą");
      return null;
    }

    if (!text) {
      setError("Įveskite komentarą");
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email, TEXT: text });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return sanitize(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? sanitize(formattedMessage) : null;
  };

  return (
    <div>
      <div className="grid grid-cols-2">
        <div className="col-span-2">
          <label className="m-2 text-center block text-sm">
            {i18n.t("footer_feedback")}
          </label>
        </div>
        <div>
          <textarea
            id="text"
            className="m-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder={i18n.t("footer_feedback_placeholder")}
            required
            onChange={(event) => setText(event?.target?.value ?? "")}
          />
          <input
            type="email"
            id="email"
            className="m-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder={i18n.t("footer_newsletter_placeholder")}
            required
            onChange={(event) => setEmail(event?.target?.value ?? "")}
            onKeyUp={(event) => handleInputKeyEvent(event)}
          />
        </div>
        <div className="align-baseline flex">
          <button
            className="mt-2.5 bg-blue-500 hover:bg-blue-400 text-white font-base text-base py-1 px-1 border-b-4 border-blue-700 hover:border-blue-400 rounded m-auto"
            onClick={handleFormSubmit}
          >
            {i18n.t("footer_feedback_submit")}
          </button>
        </div>
      </div>
      <div className="min-h-42px">
        {"sending" === status ? (
          <div className="text-blue-700 font-bold pt-2">luketelėkite... </div>
        ) : //   <Loading
        //     showSpinner
        //     message="Sending..."
        //     contentColorClass="text-white"
        //     hasVisibilityToggle={false}
        //   />
        null}
        {"error" === status || error ? (
          <>
            <div className="text-orange-700 pt-2"> Klaida</div>

            <div
              className="text-orange-700 pt-2"
              dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
            ></div>
          </>
        ) : null}
        {"success" === status && "error" !== status && !error && (
          <div
            className="text-green-600 font-bold pt-2"
            // dangerouslySetInnerHTML={{ __html: sanitize(message) }}
          >
            Jūsų komentaras išsaugotas. Ačiū :)
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterForm;
