import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import FridgeService from "../service/FridgeService";
import i18n from "../../../i18n";

class DeleteFood extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      isDataLoading: false,
    };
    this.click = this.click.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  click() {
    this.setState({ isDataLoading: true });

    FridgeService.deleteFood(this.props.foodId)
      .then((response) => {
        this.setState({ data: response.data, isDataLoading: false });
        window.location.reload(true);
      })
      .catch((err) => {
        this.setState({ data: err, isDataLoading: false });
      });
  }

  render() {
    return (
      <span>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t("fridgeTable_delete_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{i18n.t("global_warning_rusure")}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              {i18n.t("fridgeTable_edit_close")}
            </Button>
            <Button variant="primary" onClick={(this.handleClose, this.click)}>
              {i18n.t("fridgeTable_button_delete")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Button
          variant="outline-danger"
          onClick={this.handleShow}
          disabled={this.state.isDataLoading}
        >
          {" "}
          {i18n.t("fridgeTable_button_delete")}{" "}
        </Button>
      </span>
    );
  }
}
export default DeleteFood;
