import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import FridgeService from "./service/PantryService";
import UserService from "../users/service/UserService";
import i18n from "../../i18n";

class AddFoodToFridgeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      foodCategory: "",
      image: "",
      measure: "",
      quantity: "",
      weight: "",
      data: [],
      isDataLoading: false,
      show: false,
      error: "",
    };
    this.click = this.click.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  fieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  click() {
    this.setState({ isDataLoading: true });
    FridgeService.addNewFood(this.props.userFridge.id, this.state)
      .then((response) => {
        this.setState({ data: response.data, isDataLoading: false });
        window.location.reload(true);
      })
      .catch((err) => {
        this.setState({ data: err, isDataLoading: false });
        this.setState({
          error: i18n.t("fridgeTable_warning_full"),
        });
      });
  }

  render() {
    return (
      <span>
        <Modal show={this.state.show2} onHide={this.handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ color: "red" }}>
                <b>
                  <u>{this.state.error && this.state.error}</u>
                </b>
              </p>
              <p> {i18n.t("fridgeTable_add_title")}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>{i18n.t("fridgeTable_add_heading")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={i18n.t("fridgeTable_add_placeholder")}
                  name="search"
                  required
                  value={this.state.search}
                  onChange={this.fieldChange}
                  autoFocus
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              {i18n.t("fridgeTable_add_close")}
            </Button>
            <Button variant="primary" onClick={(this.handleClose, this.search)}>
              {i18n.t("fridgeTable_add_confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* SEPARATE MODALS */}

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ color: "red" }}>
                <b>
                  <u>{this.state.error && this.state.error}</u>
                </b>
              </p>
              {/* "fridgeTable_add_title": "Rasti maisto produktą",
    "fridgeTable_add_heading": "Pavadinimas",
    "fridgeTable_add_placeholder": "Vištiena, morkos, druska...",
    "fridgeTable_add_close": "Grįžti",
    "fridgeTable_add_confirm": "Pridėti!", */}
              <p>{i18n.t("fridgeTable_add_title")}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>{i18n.t("fridgeTable_add_heading")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={i18n.t("fridgeTable_add_placeholder")}
                  name="name"
                  required
                  value={this.state.name}
                  onChange={this.fieldChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>{i18n.t("fridgeTable_add_quantity")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="1, 2, 69..."
                  name="quantity"
                  required
                  value={this.state.quantity}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Label>{i18n.t("fridgeTable_add_measurment")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="cups, kg, mg, tbs..."
                  name="measure"
                  required
                  value={this.state.measure}
                  onChange={this.fieldChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              {i18n.t("fridgeTable_add_close")}
            </Button>
            <Button variant="primary" onClick={(this.handleClose, this.click)}>
              {i18n.t("fridgeTable_add_confirm")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Button
          variant="outline-info"
          onClick={this.handleShow}
          disabled={this.state.isDataLoading}
        >
          {i18n.t("fridgeTable_button_add")}
        </Button>
        {/* <div>{this.state.data.id}</div> */}
      </span>
    );
  }
}
export default AddFoodToFridgeComponent;
