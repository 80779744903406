import MailchimpSubscribe from "react-mailchimp-subscribe";
import FeedbackComponent from "./FeedbackComponent";

const NewsletterSubscribe = () => {
  const MAILCHIMP_URL = process.env.REACT_APP_PUBLIC_MAILCHIMP_URL;
  return (
    <>
      <MailchimpSubscribe
        url={MAILCHIMP_URL}
        render={(props) => {
          const { subscribe, status, message } = props || {};
          return (
            <>
              <FeedbackComponent
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            </>
          );
        }}
      />
    </>

    // <MailchimpSubscribe
    //   url={process.env.PREACT_APP_PUBLIC_MAILCHIMP_URL}
    //   render={({ subscribe, status, message }) => (
    //     <div>
    //       <p>{console.log(process.env.PREACT_APP_PUBLIC_MAILCHIMP_URL)}</p>
    //       <NewsletterForm
    //         status={status}
    //         message={message}
    //         onValidated={(formData) => subscribe(formData)}
    //       />
    //     </div>
    //   )}
    // />
  );
};

export default NewsletterSubscribe;
