import React from "react";
import UserService from "./service/UserService";
import ButtonDelete from "./ButtonDelete";
import ButtonUpdateUser from "./ButtonUpdateUser";
import PostNewUser from "./PostNewUser";
import { Container, Table } from "react-bootstrap";

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    UserService.getUsers().then((response) => {
      this.setState({ users: response.data });
    });
  }

  render() {
    return (
      <div>
        {this.state.users && (
          <h3 className="text-center">
            Create new, edit or delete users on the system
          </h3>
        )}
        <Container>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <td>ID</td>
                <td>Username</td>
                <td>Password</td>
                <td>Barbora User ID</td>
                <td>Barbora Cart ID</td>
                <td>Barbora Email</td>
                <td>Barbora Password</td>
                <td>Name</td>
                <td>Surname</td>
                <td>Barbora Login Cookie</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.username}</td>
                  <td>{user.password && "*****"}</td>
                  <td>{user.userId && "*****"}</td>
                  <td>{user.cartId && "*****"}</td>
                  <td>{user.barboraEmail}</td>
                  <td>{"*****"}</td>
                  <td>{user.name}</td>
                  <td>{user.surname}</td>
                  <td>{user.loginCookie && "******"}</td>
                  <PostNewUser />
                  <ButtonUpdateUser user={user} />
                  <ButtonDelete userId={user.id} />
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
        {this.state.users.length <= 0 && (
          <center>
            <PostNewUser />
          </center>
        )}
      </div>
    );
  }
}
export default TableComponent;
