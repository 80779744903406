import "./App.css";
import RouterComponent from "./components/navbar/RouterComponent";
import React from "react";
import "./i18n";
import "./styles/style.scss";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import Feedback from "feeder-react-feedback";
import "./styles/feeder-react-feedback.scss";

ReactGA.initialize(process.env.REACT_APP_GA_ID);

function App() {
  const { t } = useTranslation();

  let mybutton = document.getElementById("btn-back-to-top");

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 650 ||
      document.documentElement.scrollTop > 650
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
  // When the user clicks on the button, scroll to the top of the document
  mybutton != null && mybutton.addEventListener("click", backToTop);

  function backToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
  }

  return (
    <div className="App">
      <RouterComponent />
      <Feedback
        projectId={process.env.REACT_APP_FEEDER_PROJECT_ID}
        email={null}
        projectName="Andrew"
        postSubmitButtonMsg="Thanks! I value your Feedback"
        feedbackTypes={["Bug", "Idea"]}
      />
    </div>
  );
}

export default App;
