import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import UserService from "../service/UserService";
import FoodAlert from "../../alerts/FoodAlert";
import i18n from "./../../../i18n";

class RegisterNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      user_id: "",
      barbora_email: "",
      barbora_password: "",
      cart_id: "",
      loginCookie: "",
      surname: "",
      alert: false,
      data: "",
      isDataLoading: false,
      show: false,
      isAdmin: false,
    };
    this.click = this.click.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });
  handleAlertShow = () => this.setState({ alert: true });
  handleAlertClose = () => this.setState({ alert: false });

  fieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  click() {
    this.setState({ isDataLoading: true });

    UserService.postUser(this.state)
      .then((response) => {
        console.log("Registering user");
        this.setState({ data: response.data, isDataLoading: false });
        window.location.reload(false);
      })
      .catch((err) => {
        this.handleAlertShow();
        this.setState({ data: err, isDataLoading: false });
      });
  }
  handleChecked() {
    this.setState({ isAdmin: true });
  }

  render() {
    return (
      <div className="mt-2">
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t("register_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput9"
              >
                <Form.Label>{i18n.t("register_username")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={i18n.t("register_username_placeholder")}
                  name="username"
                  value={this.state.username}
                  onChange={this.fieldChange}
                  autoFocus
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput10"
              >
                <Form.Label>{i18n.t("register_password")}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={i18n.t("register_password_placeholder")}
                  name="password"
                  value={this.state.password}
                  onChange={this.fieldChange}
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label>{i18n.t("register_name")}</Form.Label>
                <Form.Control
                  type="name"
                  name="name"
                  placeholder={i18n.t("register_name_placeholder")}
                  value={this.state.name}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput6"
              >
                <Form.Label>{i18n.t("register_surname")}</Form.Label>
                <Form.Control
                  type="text"
                  name="surname"
                  placeholder={i18n.t("register_surname_placeholder")}
                  value={this.state.surname}
                  onChange={this.fieldChange}
                />
              </Form.Group> */}
              {/* <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  htmlFor="customCheck1"
                  label="Create Admin?"
                  id="isAdmin"
                  defaultChecked={this.state.isAdmin}
                  onChange={this.handleChecked}
                />
              </Form.Group> */}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              {i18n.t("register_button_cancel")}
            </Button>
            <Button variant="primary" onClick={(this.handleClose, this.click)}>
              {i18n.t("register_button_confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.alert !== false && (
          <FoodAlert
            alert={i18n.t("global_warning_login_title")}
            message={i18n.t("global_warning_login_message")}
            showExtraButton={false}
            refresh={true}
          />
        )}
        <span>
          <Button
            variant="secondary"
            onClick={this.handleShow}
            disabled={this.state.isDataLoading}
          >
            {i18n.t("register_button_register")}
          </Button>
        </span>
      </div>
    );
  }
}
export default RegisterNewUser;
