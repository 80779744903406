import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Footer from "../components/navbar/(old)Footer";
import RecipeIngredientsCommponent from "../components/food-search/RecipeIngredientsCommponent";
import FoodAlert from "../components/alerts/FoodAlert";
import FridgeService from "../components/fridge/service/PantryService";
import i18n from "./../i18n";
import ApiService from "../components/api/ApiService";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import FilterButton from "../components//food-search/FilterButton";
import PlaceholdersCustom from "../components/food-search/Placeholders/PlaceholdersCustom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const FoodSearch = (props) => {
  const [keyword, setKeyword] = useState("");
  const [foodRecipes, setFoodRecipes] = useState([]);
  const [alert, setAlert] = useState("");
  // Used for mobile, because ther footer is not at the bottom
  const [displayFooter, setDisplayFooter] = useState(false);
  const [lastSearch, setLastSearch] = useState("");

  const [useAllProducts, setUseAllProducts] = useState(false);
  const [ignorePantry, setIgnorePantry] = useState(false);
  const [dummy2, setDummy2] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  var l = false;

  const handleIgnorePantry = (e) => {
    e.preventDefault();
    setIgnorePantry(!ignorePantry);
  };
  const handleDummy2 = (e) => {
    e.preventDefault();
    setDummy2(!dummy2);
  };

  const handleUseAllProducts = (e) => {
    e.preventDefault();
    setUseAllProducts(!useAllProducts);
  };

  const handleIsLoading = () => {
    setIsLoading(!isLoading);
  };

  let isChecked;
  const API_KEY = "f686b561bc2443e9b96ec5190146425f";
  const BACKUP_API_KEY = "0cfc4addf7564bd9b69a73949b9beced";

  const getFoodData = async (keywords) => {
    var query = keywords;
    switch (i18n.language) {
      case "lt":
        try {
          var response = await ApiService.translateString(query, "en", "lt");
          query = response.data;
        } catch (err) {
          console.log(err);
        }
        break;
      case "en":
        // query = keywords;
        break;
      default:
        break;
    }

    if (keyword !== "" || keyword !== null) {
      const results = await ApiService.searchFood(
        BACKUP_API_KEY,
        query,
        25,
        useAllProducts ? 1 : 2,
        ignorePantry ? 1 : 2,
        // isChecked ? 1 : 2,
        false
      );
      // setTimeout(() => {
      //   setFoodRecipes(results.data);
      // }, "30000");
      setFoodRecipes(results.data);

      setLastSearch(keyword);
      setKeyword("");
      query = "";
    } else {
      setAlert(i18n.t("global_warning_foodSearch_message"));
    }

    return new Promise();
  };

  const foodSubmit = async (e) => {
    e.preventDefault();

    // await getFoodData(keyword);
    setIsLoading(true);
    l = true;
    console.log(l);
    await getFoodData(keyword).then(function () {
      l = false;
      setIsLoading(false);
    });
    console.log(l);

    setDisplayFooter((displayFooter) => true);
  };

  const search = async (event) => {
    event.preventDefault();
    var response = await FridgeService.getFridgeFoodsString(props.userData.id);
    setKeyword("");
    setDisplayFooter((displayFooter) => true);
  };

  const inputChange = (e) => {
    setKeyword(e.target.value);
  };
  return (
    <Container>
      {props.userData.hasPaidAccess ? (
        <div role="main" className="mx-8">
          <h1 className="text-center fs-1 fw-bold">
            {i18n.t("foodSearch_title")}
          </h1>
          <div>
            <Form>
              {alert !== "" && (
                <FoodAlert
                  alert={alert}
                  message={i18n.t("global_warning")}
                  showExtraButton={false}
                  refresh={true}
                />
              )}
              <div className="flex ...">
                <div className="flex-auto w-64 shrink">
                  <Form.Control
                    type="text"
                    placeholder={i18n.t("foodSearch_search_placeholder")}
                    onChange={inputChange}
                    value={keyword}
                    className={"my-4 rounded-2xl"}
                  />
                </div>
                <div className="flex-none ">
                  <button
                    className="rounded-2xl my-2 mx-4 bg-blue-500 hover:bg-blue-400 text-white font-bold text-xl py-2 px-2.5 border-b-4 border-blue-700 hover:border-blue-500 "
                    onClick={foodSubmit}
                    variant="primary"
                    type="submit"
                    value="search"
                  >
                    <MagnifyingGlassIcon className="font-bold h-12 w-12 " />
                    {/* {i18n.t("foodSearch_button_search")} */}
                  </button>
                </div>
              </div>

              <div
                className={
                  "grid grid-cols-3 gap-4 " +
                  (foodRecipes.length === 0 ? "" : "hidden")
                }
              >
                <div className="col-span-3 font-semibold text-xl mb-2">
                  {i18n.t("foodSearch_filters_title")}
                </div>
                <Container>
                  <Row>
                    <Col sm={8}>
                      {" "}
                      <FilterButton
                        onClick={handleUseAllProducts}
                        isClicked={useAllProducts}
                        bgColor={"bg-green-400"}
                        borderColor={"border-green-400"}
                        text={i18n.t("foodSearch_filters_useAll_filter")}
                      />
                    </Col>
                    <Col sm={8}>
                      <FilterButton
                        onClick={handleIgnorePantry}
                        isClicked={ignorePantry}
                        bgColor={"bg-green-400"}
                        borderColor={"border-green-400"}
                        text={i18n.t("foodSearch_filters_ignorePantry_filter")}
                      />
                    </Col>
                    <Col sm={8}>
                      {" "}
                      <FilterButton
                        onClick={handleDummy2}
                        isClicked={dummy2}
                        bgColor="bg-blue-400"
                        borderColor={"border-blue-400"}
                        text={"dummy that is blue"}
                      />
                    </Col>
                  </Row>
                </Container>

                <div className="col-span-3">
                  {isLoading && <PlaceholdersCustom />}
                </div>
              </div>

              {props.userData.id >= 1 && props.userData.fridge !== null && (
                <button variant="light" value="search" disabled>
                  {i18n.t("foodSearch_button_between")}
                </button>
              )}
              {props.userData.id >= 1 && props.userData.fridge !== null && (
                <button
                  className="mx-4 bg-emerald-600 hover:bg-emerald-500 text-white font-bold text-xl py-2 px-4 border-b-4 border-emerald-700 hover:border-emerald-500 rounded"
                  onClick={search}
                  variant="warning"
                  type="submit"
                  value="search"
                >
                  {i18n.t("foodSearch_button_search_with_pantry")}
                </button>
              )}
            </Form>
          </div>
          <div>
            {/* <CardGroup className="col d-flex justify-content-center"> */}
            <div
              className={
                "grid grid-cols-5 gap-3 " +
                (foodRecipes.length === 0 ? "invisible" : "")
              }
            >
              {foodRecipes !== null &&
                foodRecipes.map((recipe, uuid) => (
                  <RecipeIngredientsCommponent
                    key={uuid}
                    title={recipe.title}
                    hits={recipe}
                    cartId={props.userData.cartId}
                    userId={props.userData.id}
                  />
                ))}
            </div>
            {/* </CardGroup> */}
          </div>
          {displayFooter ? <Footer /> : null}
        </div>
      ) : (
        <div>
          <h2 className="">Sorry You Have no Access :'(</h2>
          <a href="/#pricing" type="button" className="btn btn-outline-primary">
            Support
          </a>
        </div>
      )}
    </Container>
  );
};
export default FoodSearch;
