import SinglePlaceholder from "./SinglePlaceholder";
import FilterButton from "../../../components//food-search/FilterButton";

const PlaceholderCustom = () => {
  return (
    <>
      <div className="grid grid-cols-5">
        <div>
          <div className="font-semibold text-xl mb-2 ">Panaudoti Filtrai</div>
          <FilterButton
            bgColor={"bg-yellow-400"}
            borderColor={"border-yellow-400"}
            text={"‎‎‎"}
          />
          <div className="font-semibold text-xl mb-2 ">Galimi Filtrai</div>
          <FilterButton
            bgColor={"bg-yellow-400"}
            borderColor={"border-yellow-400"}
            text={"‎‎‎"}
          />
        </div>
        <div className="col-span-4">
          <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
            <SinglePlaceholder />
            <SinglePlaceholder />
            <SinglePlaceholder />
            <SinglePlaceholder />
            <SinglePlaceholder />
            <SinglePlaceholder />
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaceholderCustom;
