import React, { useState } from "react";
import { HighlightComponent } from "../alerts/HighlightPriceComponent";
import UserService from "../users/service/UserService";
import FoodAlert from "../alerts/FoodAlert";
// import { useSession } from "next-auth/react";

export const plans = [
  {
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_bIY3ezg263zW6iY144"
        : "",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1Pc80pRwp4iaz7W5SbS5hLsH"
        : "",
    price: 6.99,
    duration: "/mo",
  },
  {
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_eVag1lg269Yk9va4gh"
        : "",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1Pc89aRwp4iaz7W5IXZJ2Qhc"
        : "",
    price: 69,
    duration: "/yr",
  },
  {
    link: process.env.NODE_ENV === "development" ? "" : "",
    priceId: process.env.NODE_ENV === "development" ? "" : "",
    price: 169,
    duration: "/One Time Payment",
  },
];

function Pricing(props) {
  const [showAlert, setShowAlert] = useState(false);

  // const history = useNavigate();
  function sendPriceId(priceId) {
    if (sessionStorage.getItem("userData") !== null && priceId !== null) {
      var userData = JSON.parse(sessionStorage.getItem("userData"));
      console.log(
        "User ID " + userData.id + ": Price ID - " + userData.priceId
      );
      sessionStorage.setItem("priceId", priceId);
      // UserService.updateUserPriceId(userData.id, priceId, true); // REMOVED, because Useless?
    }
  }

  const loginPage = () => {
    setShowAlert((current) => !current);
    // setTimeout(() => {
    //   history("/login");
    // }, 6000);
  };

  return (
    <div>
      <div
        className="pricing-header p-3 pb-md-4 mx-auto text-center"
        style={{ width: "80%" }}
      >
        <h1 className="display-4 fw-normal">Pricing</h1>
        <p className="fs-5 text-muted">
          Different Tiers for supporting the Channel and Maintaining the Hosting
          Costs
        </p>
      </div>
      {showAlert && (
        <FoodAlert
          message={"Not Registered!"}
          alert={"You must Log-in to an Account First"}
          showExtraButton={true}
          buttonMessage={"Register/Log-in"}
          buttonLink={"/login"}
          refresh={false}
        ></FoodAlert>
      )}
      {/* <div
        className="row justify-content-md-center text-center"
        // style={{ width: "80%" }}
      > */}
      <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
        {/* {!props.email ? (
          <div className="col hover:scale-105 transform transition duration-500">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Free</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  $0<small className="text-muted fw-light">/mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>0 Searches /day</li>
                  <li>0 Pantry places</li>
                  <li>NO E-mail support</li>
                  <li>NO Emotional support {":("}</li>
                </ul>
                <button
                  type="button"
                  className="w-100 btn btn-lg btn-outline-primary"
                  onClick={loginPage}
                >
                  Sign up for free
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 text-muted fw-normal">
                  <del>Free</del>
                </h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title text-muted">
                  <del>
                    $0<small className="text-muted fw-light">/mo</small>
                  </del>
                </h1>
                <ul className="list-unstyled mt-3 mb-4 text-muted">
                  <li>0 Searches /day</li>
                  <li>0 Pantry places</li>
                  <li>NO E-mail support</li>
                  <li>NO Emotional support {":("}</li>
                </ul>
                <button
                  type="button"
                  className="w-100 btn btn-lg btn-outline-secondary"
                  disabled
                >
                  <del>Sign up for free</del>
                </button>
              </div>
            </div>
          </div>
        )} */}

        {/* <div className="col col-lg-5 hover:scale-105 transform transition duration-500">
         */}
        <div className="col hover:scale-105 transform transition duration-500">
          <div className="card mb-4 rounded-3 shadow-sm">
            <div className="card-header py-3">
              <h4 className="my-0 fw-normal">🍳Homecook</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                ${plans[0].price}
                <small className="text-muted fw-light">
                  {plans[0].duration}{" "}
                </small>
              </h1>
              <ul className="list-unstyled mt-3 mb-4">
                <li>
                  <u>
                    <b>25</b> Pantry Items
                  </u>
                </li>
                <li>
                  <u>
                    <b>100</b> Searches /day
                  </u>
                </li>

                <li>E-mail support</li>
                <li>Cancel Anytime</li>
                <li>Emotional support {":)"}</li>
              </ul>
              {/* {props.email ? (

              ):()} */}
              <a
                type="button"
                className="w-100 btn btn-lg btn-outline-primary"
                target={props.email ? "_blank" : "_self"}
                rel="noreferrer"
                href={
                  props.email &&
                  plans[0].link + "?prefilled_email=" + props.email
                }
                onClick={
                  !props.email
                    ? loginPage
                    : () => {
                        sendPriceId(plans[0].priceId);
                      }
                }
              >
                Support
              </a>
            </div>
          </div>
        </div>
        <div className="col hover:scale-105 transform transition duration-500">
          <HighlightComponent
            text="BARGAIN"
            color="blue-700"
          ></HighlightComponent>
          <div className="card mb-4 rounded-3 shadow-sm  border-primary">
            <div className="card-header py-3 text-white bg-primary border-primary">
              <h4 className="my-0 fw-normal">👨‍🍳Chef</h4>
            </div>

            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                ${plans[1].price}
                <small className="text-muted fw-light">
                  {plans[1].duration}{" "}
                  <span>
                    + (<u>14-days FREE</u>)
                  </span>
                </small>
              </h1>
              <ul className="list-unstyled mt-3 mb-4">
                <li>
                  <u>
                    <b>500</b> Pantry Items
                  </u>
                </li>
                <li>
                  <u>
                    <b>∞</b> Searches /day
                  </u>
                </li>
                <li>E-mail support</li>
                <li>Cancel Anytime</li>
                <li>HUGE Emotional support :)</li>
              </ul>
              <a
                type="button"
                className="w-100 btn btn-lg btn-primary"
                target={props.email ? "_blank" : "_self"}
                rel="noreferrer"
                href={
                  props.email &&
                  plans[1].link + "?prefilled_email=" + props.email
                }
                onClick={
                  !props.email
                    ? loginPage
                    : () => {
                        sendPriceId(plans[1].priceId);
                      }
                }
              >
                Support
              </a>
            </div>
          </div>
        </div>
        <div className="col hover:scale-105 transform transition duration-500">
          <HighlightComponent
            text="LIMITED TIME"
            color="red-700"
          ></HighlightComponent>
          <div className="card mb-4 rounded-3 shadow-sm border-danger">
            <div className="card-header py-3">
              <h4 className="my-0 fw-normal">💪Alpha Supporter</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                ${plans[2].price}
                <small className="text-muted fw-light">
                  {plans[2].duration}{" "}
                </small>
              </h1>
              <ul className="list-unstyled mt-3 mb-4">
                <li>
                  <u>
                    <b></b>∞ Pantry Items
                  </u>
                </li>
                <li>
                  <u>
                    <b>∞</b> Searches /day
                  </u>
                </li>

                <li>E-mail support</li>
                <li>Cancel Anytime</li>
                <li>Emotional support Off the Charts!</li>
              </ul>
              {/* {props.email ? (

              ):()} */}
              <a
                type="button"
                className="w-100 btn btn-lg btn-outline-danger"
                target={props.email ? "_blank" : "_self"}
                rel="noreferrer"
                href={
                  props.email &&
                  plans[2].link + "?prefilled_email=" + props.email
                }
                onClick={
                  !props.email
                    ? loginPage
                    : () => {
                        sendPriceId(plans[2].priceId);
                      }
                }
              >
                Support
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pricing;
