const FilterButton = ({ onClick, isClicked, bgColor, borderColor, text }) => {
  return (
    <>
      <div className="flex flex-wrap justify-center space-x-2">
        <div
          // weird bug: hover might not appear. Color only appears if it is handwritten once and then replaced with props.
          className={
            "hover:" +
            borderColor +
            " border-2 border-gray-100 px-4 py-2 rounded-lg text-gray-700 font-semibold w-max cursor-pointer active:bg-gray-300 transition duration-300 ease " +
            (isClicked ? bgColor + " " + borderColor : "dark:text-white")
          }
          onClick={onClick}
        >
          {text}
        </div>
      </div>
    </>
  );
};

export default FilterButton;
