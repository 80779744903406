const SinglePlaceholder = () => {
  return (
    <div
      className=" m-2 hover:cursor-wait hover:opacity-50"
      variant="outline-success"
    >
      <div
        data-placeholder
        className="my-2 mr-2 h-64 w-72 rounded-3 relative bg-gray-100"
      ></div>
      <div
        data-placeholder
        className="h-6 w-64 overflow-hidden relative bg-gray-100"
      ></div>
    </div>
  );
};

export default SinglePlaceholder;
