import React from "react";
import { host } from "../../global/Variables";
import UserService from "./service/UserService";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";

class ButtonUpdateUser extends React.Component {
  BACKEND_USERS_REST_API_URL = `${host}/users/${this.props.user.id}`;

  constructor(props) {
    super(props);
    this.state = {
      username: this.props.user.username,
      password: this.props.user.password,
      name: this.props.user.name,
      user_id: this.props.user.userId,
      barbora_email: this.props.user.barboraEmail,
      barbora_password: this.props.user.barboraPassword,
      cart_id: this.props.user.cartId,
      login_cookie: this.props.user.loginCookie,
      surname: this.props.user.surname,
      data: "",
      isDataLoading: false,
      formErrors: { barbora_email: "", barbora_password: "" },
      emailValid: false,
      passwordValid: false,
      formValid: false,
      show: false,
    };
    this.click = this.click.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  fieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  click() {
    this.setState({ isDataLoading: true });
    UserService.updateUser(this.props.user.id, this.state)
      .then((response) => {
        this.setState({ data: response.data, isDataLoading: false });
        window.location.reload(false);
      })
      .catch((err) => {
        this.setState({ data: err, isDataLoading: false });
      });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "barbora_email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " is invalid";
        break;
      case "barbora_password":
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? "" : " is too short";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid && this.state.passwordValid,
    });
  }

  render() {
    return (
      <td>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update existing user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="username"
                  name="username"
                  required
                  value={this.state.username}
                  onChange={this.fieldChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput10"
              >
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="password123"
                  name="password"
                  required
                  value={this.state.password}
                  onChange={this.fieldChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>User ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="12345-564654-asdasd-asdasd"
                  name="user_id"
                  value={this.state.user_id}
                  onChange={this.fieldChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Barbora Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="email@example.com"
                  name="barbora_email"
                  value={this.state.barbora_email}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Label>Barbora Password</Form.Label>
                <Form.Control
                  type="password"
                  name="barbora_password"
                  placeholder="password123"
                  value={this.state.barbora_password}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput4"
              >
                <Form.Label>Cart ID</Form.Label>
                <Form.Control
                  type="text"
                  name="cart_id"
                  placeholder="dasdasd-as984as9-asdasd"
                  value={this.state.cart_id}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="John"
                  value={this.state.name}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput6"
              >
                <Form.Label>Surname</Form.Label>
                <Form.Control
                  type="text"
                  name="surname"
                  placeholder="Doe"
                  value={this.state.surname}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput7"
              >
                <Form.Label>Cookie ID</Form.Label>
                <Form.Control
                  type="text"
                  name="login_cookie"
                  placeholder="Cookie ID from response headers"
                  value={this.state.login_cookie}
                  onChange={this.fieldChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(this.handleClose, this.click)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Button
          variant="outline-success"
          onClick={this.handleShow}
          disabled={this.state.isDataLoading}
        >
          Edit
        </Button>
      </td>
    );
  }
}
export default ButtonUpdateUser;
