import axios from "axios";
import { host } from "../../global/Variables";

class ApiService {
  translateString(title, to, from) {
    var words = { words: title };
    return axios({
      method: "POST",
      url: `${
        process.env.hostUrl || `${host}`
      }/utils/translate?from=${from}&to=${to}`,
      data: words,
    });
  }

  searchFood(API_KEY, keyword, number, ranking, ignorePantry) {
    return axios({
      method: "GET",
      url: `https://api.spoonacular.com/recipes/findByIngredients?apiKey=${API_KEY}`,
      params: {
        ingredients: keyword,
        number: number,
        ignorePantry: ignorePantry,
        ranking: ranking,
      },
    });
  }

  // translateStringArray(title, to, from) {
  //   var words = { words: title };
  //   return axios({
  //     method: "POST",
  //     url: `${
  //       process.env.hostUrl || `${host}`
  //     }/utils/translateArray?from=${from}&to=${to}`,
  //     data: words,
  //   });
  // }
}
export default new ApiService();
