import axios from "axios";
import { host } from "../../../global/Variables";

const getJwtToken = () => {
  return localStorage.getItem("USER_TOKEN");
};

class FridgeService {
  fetchFridges(userId) {
    return axios({
      method: "GET",
      url: `${process.env.hostUrl || `${host}`}/fridge/${userId}`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }

  postNewFridge(id, fridge) {
    return axios({
      method: "PUT",
      url: `${process.env.hostUrl || `${host}`}/fridge?userId=${id}`,
      data: fridge,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }

  deleteFridge(id) {
    return axios({
      method: "DELETE",
      url: `${process.env.hostUrl || `${host}`}/fridge/${id}`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }

  addNewFood(id, food) {
    return axios({
      method: "POST",
      url: `${process.env.hostUrl || `${host}`}/food/${id}`,
      data: food,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }

  getFridgeFoodsString(id) {
    return axios({
      method: "GET",
      url: `${
        process.env.hostUrl || `${host}`
      }/fridge/foods/${id}/getAllString`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }

  deleteFood(id) {
    return axios({
      method: "DELETE",
      url: `${process.env.hostUrl || `${host}`}/food/${id}`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }

  updateFood(id, food) {
    return axios({
      method: "PUT",
      url: `${process.env.hostUrl || `${host}`}/food/${id}`,
      data: food,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }

  updateFridgeFood(id, food) {
    return axios({
      method: "PUT",
      url: `${process.env.hostUrl || `${host}`}/fridge/${id}`,
      data: food,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }
}
export default new FridgeService();
