import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import i18n from "../../i18n";

const FoodAlert = ({
  alert,
  message,
  showExtraButton,
  buttonMessage,
  buttonLink,
  refresh,
}) => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  function reload() {
    window.location.reload(true);
  }

  return (
    <Modal show={show} onHide={refresh ? (handleClose, reload) : handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{alert}</Modal.Body>
      <Modal.Footer>
        {showExtraButton && (
          <a
            className="btn btn-primary"
            type="button"
            variant="primary"
            href={buttonLink}
          >
            {buttonMessage}
          </a>
        )}

        <Button
          variant="danger"
          onClick={refresh ? (handleClose, reload) : handleClose}
        >
          {i18n.t("global_close_button")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FoodAlert;
