import React from "react";
import { Button } from "react-bootstrap";
import UserService from "../users/service/UserService";
import FoodAlert from "../alerts/FoodAlert";

class AddToCartButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      product_id: "",
      cart_id: this.props.cart_id,
      quantity: "1",
      unit: "0",
      isDataLoading: false,
      alert: "",
    };
    this.click = this.click.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  click() {
    this.props.missingData.map((food) =>
      UserService.getBarboraId(this.props.user_id, food.name)
        .then((response) => {
          this.setState({ product_id: response.data.barbora_food_id });
          return UserService.addToBarboraCart(this.state, this.props.user_id);
        })
        .then((response) => {
          this.setState({ alert: "Item added sucessfully!" });
          this.setState({ data: response.data, isDataLoading: false });
        })
        .catch((err) => {
          this.setState({ alert: "Something went wrong." });
          this.setState({ data: err, isDataLoading: false });
        })
    );
  }

  render() {
    return (
      <span>
        {this.state.alert !== "" && (
          <FoodAlert
            alert={this.state.alert}
            message={"Barbora carts"}
            showExtraButton={false}
            refresh={true}
          />
        )}
        <Button
          variant="outline-primary"
          onClick={this.click}
          disabled={this.state.isDataLoading}
        >
          {this.props.message}
        </Button>
      </span>
    );
  }
}
export default AddToCartButton;
