import React, { useEffect, useState } from "react";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import RecipeIngredients from "./RecipeIngredients";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import AddToCartButton from "../barbora/AddToCartButton";
import RecipeDoneButton from "./RecipeDoneButton";
import i18n from "../../i18n";
import ApiService from "../api/ApiService";
import {
  ArrowDownTrayIcon,
  EnvelopeIcon,
  CalendarIcon,
} from "@heroicons/react/24/solid";

const RecipeIngredientsCommponent = ({ title, hits, cartId, userId }) => {
  const { image, usedIngredients, missedIngredients } = hits;

  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [cardTitle, setCardTitle] = useState("");
  const [openInstructions, setOpen] = useState(false);
  const [openDiets, setOpenDiets] = useState(false);

  useEffect(() => {
    {
      ApiService.translateString(title, "lt", "en")
        .then((response) => {
          setCardTitle(response.data);
        })
        .catch(() => setCardTitle("failed"));
    }
  }, []);

  const removeHtmlTags = (html) => {
    var div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const handleClose = (usedIngredients) => {
    setShow(false);
    console.log(usedIngredients);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const API_KEY = "f686b561bc2443e9b96ec5190146425f";
  const BACKUP_API_KEY = "0cfc4addf7564bd9b69a73949b9beced";

  const options = {
    method: "GET",
    url: `https://api.spoonacular.com/recipes/${hits.id}/information?apiKey=${API_KEY}`,
    params: {
      includeNutrition: false,
    },
  };

  const handleUrl = () => {
    axios
      .request(options)
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        setData(err);
      });
  };

  return (
    <Card key={uuid}>
      <Modal show={show} onHide={() => handleClose(usedIngredients)} centered>
        <Modal.Header closeButton>
          <Modal.Title> {data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-2 gap-1">
            <div
              className="col-span-1 font-bold"
              style={{ textDecorationLine: "underline" }}
            >
              {i18n.t("recipeInfoCard_ingredients_name")}{" "}
            </div>
            <div
              className="font-bold"
              style={{ textDecorationLine: "underline" }}
            >
              {i18n.t("recipeInfoCard_ingredients_amount")}{" "}
            </div>
            <RecipeIngredients
              ingredients={usedIngredients}
              isExistingProduct={true}
            />
            <RecipeIngredients
              ingredients={missedIngredients}
              isExistingProduct={false}
            />
            <div className="col-span-2">
              <center>
                <hr />
              </center>
            </div>
            <div className="col-span-1 font-medium">
              {i18n.t("recipeInfoCard_portion_count")}:
            </div>
            <div className="font-bold">{data.servings}</div>
            <div className="col-span-1 font-medium">
              {i18n.t("recipeInfoCard_prep_time")}:
            </div>
            <div className="font-bold"> ~{data.readyInMinutes} min</div>
          </div>
          {data.diets && (
            <div className="col-span-2">
              <center>
                <hr />
              </center>
            </div>
          )}
          <center>
            {data.instructions && (
              <div className="col-span-2 grid">
                <Button
                  onClick={() => setOpen(!openInstructions)}
                  aria-controls="collapse-text-instructions"
                  aria-expanded={openInstructions}
                >
                  Instructions
                </Button>
              </div>
            )}
            <br></br>
            {data.diets && (
              <div className="col-span-2 grid">
                <Button
                  onClick={() => setOpenDiets(!openDiets)}
                  aria-controls="collapse-text-diets"
                  aria-expanded={openDiets}
                  variant="success"
                >
                  Diets
                </Button>
              </div>
            )}
          </center>
          <br></br>
          <Collapse in={openInstructions}>
            <div className="col-span-2" id="collapse-text-instructions">
              {removeHtmlTags(data.instructions).length >= 250
                ? removeHtmlTags(data.instructions).substring(0, 250) + "..."
                : removeHtmlTags(data.instructions)}
            </div>
          </Collapse>
          <Collapse in={openDiets}>
            <Form>
              {
                <div key={`inline-checkbox`} className="mb-3">
                  <Form.Check
                    inline
                    label="Vegetarian"
                    name="group1"
                    type={"checkbox"}
                    id={`inline-checkbox-1`}
                    onclick="return false;"
                    checked={data.vegetarian ? "checked" : ""}
                  />
                  <Form.Check
                    inline
                    label="Vegan"
                    name="group1"
                    type={"checkbox"}
                    id={`inline-checkbox-2`}
                    onclick="return false;"
                    checked={data.vegan ? "checked" : ""}
                  />
                  <Form.Check
                    inline
                    label="Gluten free"
                    name="group1"
                    type={"checkbox"}
                    id={`inline-checkbox-2`}
                    onclick="return false;"
                    checked={data.glutenFree ? "checked" : ""}
                  />
                  <Form.Check
                    inline
                    label="Dairy free"
                    name="group1"
                    type={"checkbox"}
                    id={`inline-checkbox-2`}
                    onclick="return false;"
                    checked={data.dairyFree ? "checked" : ""}
                  />
                </div>
              }
            </Form>
          </Collapse>
          <div className="col-span-2">
            <center>
              <hr />
            </center>
          </div>
          <center>
            <div
              className={
                userId ? "grid grid-cols-2 gap-1" : "grid grid-cols-1 gap-1"
              }
            >
              <div>
                <button
                  className="mt-3 font-bold mt-2.5 bg-blue-500 hover:bg-blue-400 text-white font-base text-base py-2 px-2 border-b-4 border-blue-700 hover:border-blue-400 rounded m-auto"
                  onClick={() => openInNewTab(data.sourceUrl)}
                >
                  {i18n.t("recipeInfoCard_link")}
                </button>
              </div>

              <div>
                {userId ? (
                  <RecipeDoneButton
                    key={uuid}
                    userId={userId}
                    food1={missedIngredients}
                    food2={usedIngredients}
                  />
                ) : null}
              </div>
            </div>
          </center>
          <div className="grid grid-cols-11 mt-3">
            <div className="col-span-4 font-medium">Išsaugoti receptą: </div>
            <div className="col-span-1">
              <ArrowDownTrayIcon className="h-6 w-6 " />
            </div>
            <div className="col-span-1">
              <EnvelopeIcon className=" h-6 w-6 " />
            </div>
            <div className="col-span-1">
              <CalendarIcon className="col-span-2 h-6 w-6 " />
            </div>
          </div>
          <span></span>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            {i18n.t("global_close_button")}
          </Button> */}
        {/* Hidden for now and it has some weird error */}

        {/* also hidden for now since Barbora isn't realeased */}
        {/* {cartId !== "" && cartId && (
            <AddToCartButton
              missingData={missedIngredients}
              cart_id={cartId}
              user_id={userId}
              message={'Add available items to "Barbora" cart'}
            />
          )} */}
        {/* </Modal.Footer> */}
      </Modal>
      <div
        className="w-42 lg:48 m-2 hover:cursor-pointer hover:opacity-50"
        onClick={function () {
          setShow(!show);
          handleUrl();
        }}
        variant="outline-success"
      >
        {/* <Card.Img variant="top" src={image} akt={title} /> */}
        <img variant="top" src={image} akt={title} alt={title} />
        {/* <Card.Body> */}
        {/* <Card.Title className="text-sm">{title}</Card.Title> */}
        <h3 className="text-left text-base md:text-lg">
          {i18n.language === "lt" && cardTitle !== "failed" ? cardTitle : title}
        </h3>
        {/* <Button
            onClick={function () {
              setShow(!show);
              handleUrl();
            }}
            variant="outline-success"
          >
            Ingredients List
          </Button> */}
        {/* <Card.Text></Card.Text>
        </Card.Body> */}
      </div>
    </Card>
  );
};

export default RecipeIngredientsCommponent;
