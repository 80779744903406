import MailchimpSubscribe from "react-mailchimp-subscribe";
import NewsletterComponent from "./NewsletterComponent";

const NewsletterSubscribe = () => {
  const MAILCHIMP_URL = process.env.REACT_APP_PUBLIC_MAILCHIMP_URL;
  return (
    <>
      <MailchimpSubscribe
        url={MAILCHIMP_URL}
        render={(props) => {
          const { subscribe, status, message } = props || {};
          return (
            <>
              <NewsletterComponent
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            </>
          );
        }}
      />
    </>
  );
};

export default NewsletterSubscribe;
