import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import UserService from "../users/service/UserService";
import FoodAlert from "../alerts/FoodAlert";

class AddBarboraDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rememberMe: true,
      barboraEmail: "",
      barboraPassword: "",
      data: [],
      isDataLoading: false,
      show: false,
      alert: false,
    };
    this.click = this.click.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  handleAlertShow = () => this.setState({ alert: true });
  handleAlertClose = () => this.setState({ alert: false });

  fieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  click() {
    this.setState({ isDataLoading: true });
    UserService.connectUserToBarbora(this.props.userData.id, this.state)
      .then((response) => {
        this.setState({ data: response.data, isDataLoading: false });
        if (!response.data.success === true) {
          this.handleClose();
          this.handleAlertShow();
        } else {
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ data: err, isDataLoading: false });
        this.handleAlertShow();
      });
  }

  render() {
    return (
      <span>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Link with "Barbora" Log in Credentials</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput69"
              >
                <Form.Label>"Barbora" login e-mail</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="barbora@email.com"
                  name="barboraEmail"
                  value={this.state.barboraEmail}
                  onChange={this.fieldChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput70"
              >
                <Form.Label>"Barbora" login password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="PasswordBarbora123"
                  name="barboraPassword"
                  value={this.state.barboraPassword}
                  onChange={this.fieldChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(this.handleClose, this.click)}>
              Connect to "Barbora"
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.alert !== false && (
          <FoodAlert
            alert={"Entered account is invalid!"}
            message={"Invalid Account"}
            showExtraButton={false}
            refresh={true}
          />
        )}
        <Button variant="outline-primary" onClick={this.handleShow}>
          Link "Barbora" account!
        </Button>
      </span>
    );
  }
}
export default AddBarboraDetails;
