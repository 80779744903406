import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import UserService from "./service/UserService";

class PostNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      user_id: "",
      barbora_email: "",
      barbora_password: "",
      cart_id: "",
      loginCookie: "",
      surname: "",
      username: "",
      password: "",
      data: "",
      isDataLoading: false,
      show: false,
    };
    this.click = this.click.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  fieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  click() {
    this.setState({ isDataLoading: true });
    UserService.postUser(this.state)
      .then((response) => {
        this.setState({ data: response.data, isDataLoading: false });
        window.location.reload(false);
      })
      .catch((err) => {
        this.setState({ data: err, isDataLoading: false });
      });
  }

  render() {
    return (
      <td>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create new User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput8"
              >
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="username"
                  name="username"
                  required
                  value={this.state.username}
                  onChange={this.fieldChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput9"
              >
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="password123"
                  name="password"
                  required
                  value={this.state.password}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>User ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="12345-564654-asdasd-asdasd"
                  name="user_id"
                  value={this.state.user_id}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Barbora Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="email@example.com"
                  name="barbora_email"
                  required
                  value={this.state.barbora_email}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Label>Barbora Password</Form.Label>
                <Form.Control
                  type="password"
                  name="barbora_password"
                  placeholder="password123"
                  value={this.state.barbora_password}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput4"
              >
                <Form.Label>Cart ID</Form.Label>
                <Form.Control
                  type="text"
                  name="cart_id"
                  placeholder="dasdasd-as984as9-asdasd"
                  value={this.state.cart_id}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="John"
                  value={this.state.name}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput6"
              >
                <Form.Label>Surname</Form.Label>
                <Form.Control
                  type="text"
                  name="surname"
                  placeholder="Doe"
                  value={this.state.surname}
                  onChange={this.fieldChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput7"
              >
                <Form.Label>Cookie ID</Form.Label>
                <Form.Control
                  type="text"
                  name="login_cookie"
                  placeholder="Cookie ID from response headers"
                  value={this.state.login_cookie}
                  onChange={this.fieldChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(this.handleClose, this.click)}>
              Create User
            </Button>
          </Modal.Footer>
        </Modal>

        <Button
          variant="outline-info"
          onClick={this.handleShow}
          disabled={this.state.isDataLoading}
        >
          Create
        </Button>
      </td>
    );
  }
}
export default PostNewUser;
