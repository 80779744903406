import React, { Component } from "react";
import { Spinner } from "react-bootstrap";

class PageLoading extends React.Component {
  render() {
    return (
      <div className="d-flex justify-content-center">
        <Spinner
          className="d-block m-auto"
          animation="border"
          variant="secondary"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
}

export default PageLoading;
