import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <div>
      <h1 className="font-weight-bold">404 Error</h1>
      <h1>
        Page <code>{window.location.pathname}</code> Not Found
      </h1>

      {/* <img src="https://ih1.redbubble.net/image.1376124008.2430/st,small,507x507-pad,600x600,f8f8f8.jpg"></img> */}
      <p className="font-italic">
        You're lost. Try coming back home by pressing <Link to="/">(HERE)</Link>
      </p>
      <Helmet><meta name="robots" content="noindex"></meta></Helmet>
    </div>
  );
};

export default NotFoundPage;
