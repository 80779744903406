import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { wp } from "../api/WpApi";
import { withRouter } from "./withRouter"; // https://stackoverflow.com/a/71043072
import WpComments from "react-wordpress-comments";
// import WpComments from "./wpComments/wpComments";
import "../../styles/wpCommentStyles.scss";
// import "react-wordpress-comments/css/styles.css";
import WpService from "./WpService";

import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import data from "./_setup.json";

import PageLoading from "./pageLoading";
import { Footer } from "../main/Footer";
import { PostBadge } from "../alerts/PostBadge";

const PostView = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [posts, setPosts] = useState(null);

  let navigate = useNavigate();
  let { postName } = useParams();

  useEffect(() => {
    console.log(postName);
    getPost(postName ? postName : "404");
    console.log(posts);
  }, [postName]);

  // parseStarRating(content) {
  //   const el = document.querySelector(
  //     "#root > div > div > div > div:nth-child(7) > div > div.kksr-legend"
  //   );
  //   // return el.lenght;
  // }

  const printRecipe = () => {
    window.print();
  };
  const scrollToRecipe = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      window.history.replaceState(null, "", `#${id}`); // This replaces the URL without adding a new entry
    }
  };

  const getPost = (s) => {
    // Fetch post with slug "xyz": http://wp-api.org/node-wpapi/using-the-client/#api-query-parameters
    wp.posts()
      .slug(s)
      .then((posts) => {
        console.log("setting post");
        console.log(posts[0]);
        setIsLoading(false);
        setPosts(posts[0]); // Get first element from array
        setTags(WpService.getItemFromClassList(posts[0].class_list, "tag-"));
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  // const location = useLocation();
  if (error) {
    console.log("error...");
    return <div className="content error">Error: {error.message}</div>;
  } else if (isLoading) {
    console.log("loading...");
    return (
      <div>
        <PageLoading />
      </div>
    );
  } else if (posts !== null) {
    console.log("shit works...");
    console.log(posts);

    return (
      <div className="post container">
        <div className="flex" id="back-top">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-outline-secondary"
          >
            Back
          </button>
        </div>
        {/* <svg
            className="w-4 h-4 text-yellow-300 me-1"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 22 20"
          >
            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
          </svg>
          <p className="ms-2 text-sm font-bold text-gray-900 dark:text-white">
            {this.parseStarRating(post.content.rendered)}
          </p> */}
        <div className="text-right">
          <small className="text-muted">
            {new Date(posts.date).toLocaleDateString(
              data.format.date.locale,
              data.format.date.options
            )}
          </small>
          {posts.author === 1 && (
            <PostBadge
              text="Andrew's Cookin' Original"
              style="absolute post-badge"
              color="success"
            ></PostBadge>
          )}
        </div>
        <h1
          className="text-center"
          dangerouslySetInnerHTML={{ __html: posts.title.rendered }}
        />
        <hr />
        {tags && tags.length > 0 && (
          <div className="post-tag">
            Tags:
            <div className="container">
              {tags.map((item) => (
                <PostBadge
                  key={item}
                  text={item}
                  style="child-tag"
                  color="danger"
                ></PostBadge>
              ))}
            </div>
          </div>
        )}
        {posts.content.rendered.includes('id="recipe"') && (
          <div className="container">
            <div className="jump-to-recipe-button">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => scrollToRecipe("recipe")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-caret-down"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659" />
                </svg>{" "}
                Jump to Recipe
              </button>
            </div>
            <div className="jump-to-recipe-button">
              <button
                className="btn btn-primary"
                onClick={printRecipe}
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-printer"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                  <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                </svg>{" "}
                Print Recipe
              </button>
            </div>
          </div>
        )}

        <div
          className="post-text-wrapper text-left"
          dangerouslySetInnerHTML={{ __html: posts.content.rendered }}
        />
        <div className="text-center">
          <Link
            to={"/" + data.url.slug_posts + "/"}
            className="btn btn-secondary"
          >
            Back to Recipes
          </Link>
        </div>
        <hr />
        <div className="container form-group">
          {posts.comment_status === "open" && (
            <WpComments
              maxDepth={3} // provide depth that you want comments to be nested in a list
              pageId={posts.id} // id of a page you want to fetch comments from and post to
              hostUrl={process.env.REACT_APP_WP_HOST_PAGE} // url of your WordPress website
              allowComments={true} // can users post comments?
              user={null} // provide user if authorized, default is null. See below
              className="form-control"
            />
          )}
        </div>

        {posts.yoast_head != null && posts.yoast_head !== undefined && (
          <div>
            <Helmet>{parse(posts.yoast_head)}</Helmet>

            <Helmet>
              <script type="application/ld+json">
                {JSON.stringify(posts.yoast_head_json.schema)}
              </script>
            </Helmet>
          </div>
        )}
        {tags != null && (
          <Helmet>
            <meta name="parsely-tags" content={tags.join(",")}></meta>
          </Helmet>
        )}

        <hr />
        <Footer></Footer>
      </div>
    );
  } else {
    return (
      <div className="content 404">
        <Helmet>
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        404 - Not found!
      </div>
    );
  }
};

export default withRouter(PostView);
