import {
  authorizationRequest,
  authorizationSuccess,
  authorizationFail,
} from "../types";

const initialState = {
  user: {},
  error: "",
  loading: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case authorizationRequest:
      return { ...state, error: "", loading: true };

    case authorizationSuccess:
      const data = action.payload;
      return { ...state, error: "", loading: false, user: data };

    case authorizationFail:
      const error = action.payload;
      return { ...state, loading: false, error: error };

    default:
      return state;
  }
};

export default auth;
