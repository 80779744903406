import axios from "axios";
import { host } from "../../global/Variables";

const getJwtToken = () => {
  return localStorage.getItem("USER_TOKEN");
};

export const authService = (authRequest) => {
  return axios({
    method: "POST",
    url: `${process.env.hostUrl || `${host}`}/authorization/login`,
    data: authRequest,
  });
};

export const fetchLoggedInUser = () => {
  return axios({
    method: "GET",
    url: `${process.env.hostUrl || `${host}`}/users/me`,
    headers: {
      Authorization: "Bearer " + getJwtToken(),
    },
  });
};
