import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { wp } from "../api/WpApi";
// import { PostBadge } from "../alerts/PostBadge";
import "../../styles/wpStyles.scss";

import data from "./_setup.json";

import PageLoading from "./pageLoading";
import { Footer } from "../main/Footer";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

// Date Format: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString

const CategoryList = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [postsperpage, setPostsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(1);
  const [totalpages, setTotalPages] = useState(null);
  const [categoryInfo, setCategoryInfo] = useState(null);

  // const location = useLocation();
  let navigate = useNavigate();
  let { categoryName } = useParams();

  const readTime = (content) => {
    const wordsPerMinute = 300; // Average case.
    let result;

    let textLength = content.split(" ").length; // Split by words
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute);
      result = `~${value} min. read`;
    }
    return result;
  };

  const loadMore = () => {
    setCurrentPage((prev) => prev + 1);
    // getPosts(currentpage, location.state.id);
    getPosts(currentpage, null);
  };

  useEffect(() => {
    loadMore();
  }, []);

  const getPosts = (currPage, categoryId) => {
    wp.categories()
      .slug(categoryName)
      .then(function (cat) {
        setCategoryInfo(cat[0]);
        // .slug() queries will always return as an array
        var catPost = cat[0];
        return wp
          .posts()
          .categories(catPost.id)
          .perPage(postsperpage)
          .page(currPage);
      })
      .then(function (posts) {
        // These posts are all categorized:
        // Append post data to existing posts
        if (currPage === 1) {
          setPosts(posts);
          setTotalPages(posts._paging.totalPages);
          setIsLoading(false);
        } else {
          setPosts((prevPosts) => prevPosts.concat(posts));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  if (error) {
    return <div className="content">Error: {error.message}</div>;
  } else if (isLoading) {
    return (
      <div className="container">
        <PageLoading />
      </div>
    );
  } else if (posts != []) {
    return (
      <div className="content container">
        {/* META TAGS FOR POST LIST */}
        <Helmet>{parse(categoryInfo.yoast_head)}</Helmet>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(categoryInfo.schema)}
          </script>
        </Helmet>
        <div className="row">
          <div className="col">
            <button
              className="btn btn-outline-secondary"
              style={({ float: "left" }, { display: "flex" })}
              onClick={() => navigate(-1)}
            >
              Back to Categories
            </button>

            <h1 className="fw-bold">
              Archives of {categoryInfo.name} Category
            </h1>
            <small className="fw-light">
              {parse(categoryInfo.description)}
            </small>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="list-group col">
            {posts.map((post) => (
              <Link
                key={post.id}
                to={"/" + data.url.slug_posts + "/" + post.slug}
                className="shadow-sm list-group-item list-group-item-action transition ease-in-out delay-50 duration-300"
              >
                <h2
                  style={{ padding: 20 }}
                  dangerouslySetInnerHTML={{
                    __html: post.title.rendered,
                  }}
                />

                {post.yoast_head_json.og_image !== undefined &&
                  post.yoast_head_json && (
                    <div className="post-thumbnail">
                      <img src={post.yoast_head_json.og_image[0].url}></img>
                    </div>
                  )}
                <p
                  className="categorie-description"
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt.rendered,
                  }}
                />
                <div className="post-date absolute right-6 -translate-x-1/6 z-20">
                  <small>
                    {new Date(post.date).toLocaleDateString(
                      data.format.date.locale,
                      data.format.date.options
                    )}
                  </small>
                </div>
                <p className="post-read-time font-weight-light">
                  <u>{readTime(post.content.rendered)}</u>
                </p>
              </Link>
            ))}
          </div>
        </div>
        {currentpage <= totalpages && (
          <div className="row">
            <div className="col">
              <button
                onClick={loadMore}
                type="button"
                className="btn btn-primary d-block my-5 mx-auto"
              >
                Load more
              </button>
            </div>
          </div>
        )}
        <hr />
        <Footer></Footer>
      </div>
    );
  } else {
    return <div className="content">Nothing to View!</div>;
  }
};

export default CategoryList;
