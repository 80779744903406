import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import UserService from "./service/UserService";

class ButtonDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      isDataLoading: false,
    };
    this.click = this.click.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  click() {
    this.setState({ isDataLoading: true });

    UserService.deleteUser(this.props.userId)
      .then((response) => {
        this.setState({ data: response.data, isDataLoading: false });
        window.location.reload(false);
      })
      .catch((err) => {
        this.setState({ data: err, isDataLoading: false });
      });
  }

  render() {
    return (
      <td>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete User?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(this.handleClose, this.click)}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Button
          variant="outline-danger"
          onClick={this.handleShow}
          disabled={this.state.isDataLoading}
        >
          {" "}
          Delete{" "}
        </Button>
      </td>
    );
  }
}
export default ButtonDelete;
