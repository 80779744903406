import React, { useState } from "react";
import { connect } from "react-redux";
import {
  authenticate,
  authFailure,
  authSuccess,
} from "../../../redux/authActions";
import { authService } from "../../api/AuthService";
import {
  Alert,
  Button,
  Spinner,
  Form,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import RegisterNewUser from "./RegisterNewUser";
import i18n from "./../../../i18n";

const LoginComponent = ({ loading, error, ...props }) => {
  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const submitClick = (e) => {
    e.preventDefault();
    props.authenticate();

    authService(values)
      .then((response) => {
        if (response.status === 200) {
          props.setUser(response.data);
          window.location.pathname = "/";
        } else {
          props.failedLogin("Something went Wrong!");
        }
      })
      .catch((err) => {
        if (err && err.response) {
          switch (err.response.status) {
            case 401:
              props.failedLogin(
                i18n.t("global_warning_login_user_unauthorized")
              );
              break;
            case 404:
              props.failedLogin(
                i18n.t("global_warning_login_user_doesnt_exist_message")
              );
              break;
            default:
              props.failedLogin(
                i18n.t("global_warning_login_server_error_message")
              );
          }
        } else {
          props.failedLogin(
            i18n.t("global_warning_login_server_error_message")
          );
        }
      });
  };

  const handleChange = (e) => {
    e.persist();
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div>
      <Container fluid="sm">
        <Row className="justify-content-md-center">
          <Col sm={6}>
            <Card>
              <Card.Header as="h5">{i18n.t("login_title")}</Card.Header>
              <Card.Body>
                <Form onSubmit={submitClick} noValidate={false}>
                  <Form.Group className="mb-3">
                    <Form.Label>{i18n.t("login_username")}</Form.Label>
                    <Form.Control
                      id="username"
                      type="text"
                      value={values.username}
                      onChange={handleChange}
                      name="username"
                      required
                      placeholder={i18n.t("login_username_placeholder")}
                    />
                    <div className="invalid-feedback">Username is invalid</div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{i18n.t("login_password")}</Form.Label>
                    <Form.Control
                      id="password"
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      name="password"
                      required
                      placeholder={i18n.t("login_password_placeholder")}
                    />
                    <div className="invalid-feedback">Password is invalid</div>
                  </Form.Group>
                  <span>
                    <Button
                      type="submit"
                      variant="primary"
                      onSubmit={submitClick}
                    >
                      {i18n.t("login_button_login")}
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  </span>{" "}
                  <RegisterNewUser />
                </Form>
                {error && (
                  <Alert style={{ marginTop: "20px" }} variant="danger">
                    {error}
                  </Alert>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const stateToProps = ({ auth: authentication }) => {
  console.log("error auth", authentication);
  return {
    loading: authentication.loading,
    error: authentication.error,
  };
};

const reaturnDispatchChangeToProps = (dispatch) => {
  return {
    authenticate: () => dispatch(authenticate()),
    setUser: (data) => dispatch(authSuccess(data)),
    failedLogin: (message) => dispatch(authFailure(message)),
  };
};

export default connect(
  stateToProps,
  reaturnDispatchChangeToProps
)(LoginComponent);
