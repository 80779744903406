class WpService {
  getItemFromClassList(classList, startsWith) {
    const tmp = classList
      .filter((item) => item.startsWith(startsWith))
      .map((item) => item.replace(startsWith, ""));
    return tmp;
  }
}

export default new WpService();
