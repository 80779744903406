import React from "react";

export const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <footer className="container">
        <p className="float-end animate-bounce" style={{ cursor: "pointer" }}>
          <a onClick={scrollToTop} className="back-to-top">
            Back to top
          </a>
        </p>
        <p>
          &copy; 2022–{new Date().getFullYear()} Andrew's Cookin' &middot;{" "}
          <a href="/privacy-conditions">Privacy</a> &middot;{" "}
          <a href="/terms-and-conditions">Terms</a>
        </p>
      </footer>
    </div>
  );
};
