import React, { Component } from "react";
import { Link } from "react-router-dom";
import { wp } from "../components/api/WpApi";
import { PostBadge } from "../components/alerts/PostBadge";
import "../styles/wpStyles.scss";
import { ItemListSchema } from "../components/seo/ItemListSchema";

import PageLoading from "../components/wp/pageLoading";
import { Footer } from "../components/main/Footer";
import data from "../components/wp/_setup.json";
import MetaTags from "../components/seo/MetaTags";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import { current } from "@reduxjs/toolkit";

class Categories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoading: true,
      totalpages: null,
      totalCategories: null,
      selectedRadio: [],
    };

    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.getCategories();
  }

  componentDidMount() {
    // Load initial
    this.loadMore();
  }
  getCategories = () => {
    // Fetch pages with search value "xyz": http://wp-api.org/node-wpapi/using-the-client/#api-query-parameters
    wp.categories()
      .perPage(100)
      .then((categories) => {
        categories != null &&
          this.setState({
            isLoading: false,
            totalCategories: categories,
          });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: true,
        });
      });
  };

  render() {
    const { totalCategories, category, error, isLoading } = this.state;
    var itemListArray = [];
    var listCount = 1;
    if (error) {
      return <div className="content">Error: {error.message}</div>;
    } else if (isLoading) {
      return (
        <div className="container">
          <PageLoading />
        </div>
      );
    } else if (totalCategories != []) {
      return (
        <div className="content container">
          <div className="row">
            <h1 className="fw-bold">Pick your favorite Category</h1>
            <small className="fw-light">and GO make some delicious food!</small>
          </div>
          <hr />
          <div className="row">
            <div className="list-group col">
              {totalCategories.map(
                (categorie) => (
                  itemListArray.push({
                    ["@type"]: "ListItem",
                    position: listCount++,
                    url: categorie.link, //has already "category" in it. Permalink is in the wp settings->permalinks
                  }),
                  (
                    <Link
                      key={categorie.id}
                      to={categorie.slug}
                      className="shadow-sm list-group-item list-group-item-action transition ease-in-out delay-50 duration-300"
                      // state={{
                      //   id: categorie.id.toString(),
                      //   yoast_head: categorie.yoast_head,
                      //   name: categorie.name,
                      //   description: categorie.description,
                      //   schema: categorie.yoast_head_json.schema,
                      // }}
                    >
                      <h1
                        style={{ padding: 20 }}
                        dangerouslySetInnerHTML={{
                          __html: categorie.yoast_head_json.title,
                        }}
                      />

                      <p
                        className="categorie-description"
                        dangerouslySetInnerHTML={{
                          __html: categorie.description,
                        }}
                      />
                    </Link>
                  )
                )
              )}
              <Helmet>
                <script type="application/ld+json">
                  {JSON.stringify(ItemListSchema(itemListArray))}
                </script>
              </Helmet>
              <Helmet>
                <link
                  rel="canonical"
                  href={
                    "https://www.andrewscookin.com/" +
                    data.url.slug_posts +
                    "/" +
                    data.url.slug_categories
                  }
                ></link>
              </Helmet>
            </div>
          </div>
          {this.state.currentpage <= this.state.totalpages && (
            <div className="row">
              <div className="col">
                <button
                  onClick={this.loadMore}
                  type="button"
                  className="btn btn-primary my-5"
                >
                  Load more
                </button>
              </div>
            </div>
          )}
          <hr />
          <Footer></Footer>
        </div>
      );
    } else {
      return <div className="content">Nothing to View!</div>;
    }
  }
}

export default Categories;
