import React from "react";
import UserComponent from "../components/users/TableComponent";
import NotFoundPage from "../components/alerts/NotFoundPage";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      data: [],
    };
  }
  render() {
    return (
      <div role="main">
        {this.props.userData.id >= 1 && (
          <h2>These are all users on the system</h2>
        )}
        {this.props.userData.id >= 1 && <UserComponent />}
        {this.props.userData.id <= 0 && <NotFoundPage />}
      </div>
    );
  }
}
export default Users;
