import React from "react";
import { Container, Table } from "react-bootstrap";
import FridgeService from "./service/FridgeService";
import AddFoodToFridgeComponent from "./AddFoodToFridgeComponent";
import DeleteFood from "./food/DeleteFood";
import AddToCartButton from "../barbora/AddToCartButton";
import UpdateFoodInFridgeComponent from "./UpdateFoodInFridgeComponent";
import i18n from "./../../i18n";

class FridgeTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      size: "",
      userId: "",
      fridges: [],
      isDataLoading: false,
      response: "",
      data: [],
      foods: [],
      arr: [],
    };
  }

  roundNumber(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  componentDidMount() {
    this.setState({ isDataLoading: true });
    if (this.props.userData.id >= 1) {
      FridgeService.fetchFridges(this.props.userData.id)
        .then((response) => {
          this.setState({ id: response.data.id });
          this.setState({ name: response.data.name });
          this.setState({ size: response.data.size });
          this.setState({ foods: response.data.foods });
          this.setState({ isDataLoading: false });
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }
  }

  render() {
    return (
      <center>
        <div>
          {/* <h1 className="text-center">
            {i18n.t("fridgeTable_title")} <u>"{this.state.name}"</u>,
          </h1> */}
          <h3 className="text-center">
            {i18n.t("fridgeTable_title_size1")} <u>{this.state.size}</u>{" "}
            {i18n.t("fridgeTable_title_size2")}.
          </h3>
          <Container>
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <td></td>
                  <td>{i18n.t("fridgeTable_item_name")}</td>
                  {/* <td>{i18n.t("fridgeTable_category")}</td> */}
                  <td>{i18n.t("fridgeTable_quantity")}</td>
                  {/* <td>Weight</td> */}
                  <td>{i18n.t("fridgeTable_actions")}</td>
                </tr>
              </thead>
              <tbody>
                {this.state.foods.map((food) => (
                  <tr key={food.id}>
                    <td>
                      {" "}
                      <img src={food.image} width={80} alt={food.food}></img>
                      {food.food}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>{food.name}</td>
                    {/* <td>{food.foodCategory}</td> */}
                    <td>
                      {this.roundNumber(food.quantity)} {food.measure}
                    </td>
                    {/* <td>{food.weight} g.</td> */}
                    <td>
                      <center>
                        <AddFoodToFridgeComponent
                          userFridge={this.props.userData.fridge}
                        />
                        <span> </span>
                        <UpdateFoodInFridgeComponent userFood={food} />
                        <span> </span>
                        <DeleteFood foodId={food.id} />
                        <span> </span>
                        {this.props.userData.cartId !== "" && (
                          <AddToCartButton
                            missingData={this.state.arr.concat(food)}
                            cart_id={this.props.userData.cartId}
                            user_id={this.props.userData.id}
                            message={'Add to "Barbora" Cart'}
                          />
                        )}
                      </center>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </div>
      </center>
    );
  }
}
export default FridgeTableComponent;
