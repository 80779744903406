import React from "react";
import CreateFridge from "../components/fridge/CreateFridge";
import UtilsService from "../components/users/service/UtilsService";
import i18n from "../i18n";

class Fridge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      data: [],
    };
  }

  render() {
    return (
      <div role="main">
        {this.props.userData.id >= 1 && this.props.userData.hasPaidAccess ? (
          <div>
            <h2>
              {i18n.t("pantry_welcome") + " "}
              <i>{UtilsService.Capitalize(this.props.userData.username)}</i>!
            </h2>
            {/* <br></br> */}
            <div>
              <CreateFridge fridgeData={this.props.userData} />
            </div>
          </div>
        ) : (
          <div>
            <h2>{i18n.t("pantry_info")}</h2>
            <a
              href="/#pricing"
              type="button"
              className="btn btn-outline-primary"
            >
              Support
            </a>
          </div>
        )}
      </div>
    );
  }
}
export default Fridge;
