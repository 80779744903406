import i18n from "../../i18n";
import NewsletterSubscribe from "./action-forms/NewsletterSubscribe";
import FeedbackSubscribe from "./action-forms/FeedbackSubscribe";

function Footer() {
  return (
    <>
      <div className="relative py-4">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-b border-gray-300"></div>
        </div>
        <div className="relative flex justify-center"></div>
      </div>

      <footer className="h-10">
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <div>
            <FeedbackSubscribe />
          </div>
          <div>
            <NewsletterSubscribe />
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
