import axios from "axios";
import { host } from "../../../global/Variables";

const BACKEND_USERS_REST_API_URL = `${host}`;

const getJwtToken = () => {
  return localStorage.getItem("USER_TOKEN");
};

class UserService {
  getUsers() {
    return axios({
      method: "GET",
      url: `${BACKEND_USERS_REST_API_URL}/users`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }
  postUser(data) {
    return axios({
      method: "POST",
      url: `${BACKEND_USERS_REST_API_URL}/users/register`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
      data: data,
    });
  }
  deleteUser(id) {
    return axios({
      method: "DELETE",
      url: `${BACKEND_USERS_REST_API_URL}/users/${id}`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }
  updateUser(id, data) {
    return axios({
      method: "PUT",
      url: `${BACKEND_USERS_REST_API_URL}/users/${id}`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
      data: data,
    });
  }
  connectUserToBarbora(id, data) {
    return axios({
      method: "PUT",
      url: `${BACKEND_USERS_REST_API_URL}/barbora/login/${id}`,
      data: data,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }
  disconnectUserFromBarbora(id) {
    return axios({
      method: "DELETE",
      url: `${BACKEND_USERS_REST_API_URL}/barbora/login/${id}`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }
  addToBarboraCart(data, id) {
    return axios({
      method: "POST",
      url: `${BACKEND_USERS_REST_API_URL}/barbora/cart/${id}`,
      data: data,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }
  getBarboraId(id, name) {
    return axios({
      method: "GET",
      url: `${BACKEND_USERS_REST_API_URL}/food/getBarboraId/${id}?foodName=${name}`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }
  cleanBarboraCart(id) {
    return axios({
      method: "DELETE",
      url: `${BACKEND_USERS_REST_API_URL}/cart/removeallitems/${id}`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
    });
  }

  updateUserPriceId(id, priceId, updatePlanBool) {
    return axios({
      method: "PUT",
      url: `${BACKEND_USERS_REST_API_URL}/users/price/${id}?priceId=${priceId}&updatePlan=${updatePlanBool}`,
      headers: {
        Authorization: "Bearer " + getJwtToken(),
      },
      data: { priceId: priceId },
    });
  }
}
export default new UserService();
