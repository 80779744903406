import AddBarboraDetails from "../components/barbora/AddBarboraDetails";
import UnlinkBarboraDetails from "../components/barbora/UnlinkBarboraDetails";

function Barbora(props) {
  return (
    <div role="main">
      {props.userData.cartId === "" && (
        <h1>Add your "Barbora" account to manage your Recipes faster!</h1>
      )}
      {props.userData.cartId === "" && (
        <AddBarboraDetails userData={props.userData} />
      )}
      {props.userData.cartId !== "" && (
        <h1>Your "Barbora" account is Linked!</h1>
      )}
      {props.userData.cartId !== "" && (
        <UnlinkBarboraDetails userData={props.userData} />
      )}
    </div>
  );
}
export default Barbora;
