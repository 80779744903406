import React from "react";
// import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link,
  // Navigate,
} from "react-router-dom";
import {
  Navbar,
  Nav,
  // NavItem,
  // NavLink,
  // Dropdown,
  Container,
} from "react-bootstrap";
import Barbora from "../../pages/Barbora";
import FoodSearch from "../../pages/FoodSearch";
import Home from "../../pages/Home";
import Users from "../../pages/Users";
import Fridge from "../../pages/Fridge";
import NotFoundPage from "../alerts/NotFoundPage";
import Terms from "../../pages/Terms";
import Privacy from "../../pages/Privacy";
import LoginComponent from "../users/login/LoginComponent";
import ThemeChanger from "../../global/ThemeChanger";
import { fetchLoggedInUser } from "../api/AuthService";
import logo from "../../resources/logo.png";
import UserService from "../users/service/UserService";
import { Trans } from "react-i18next";
import i18n from "./../../i18n";

// import PageView from "../wp/pageView";
import PostList from "../wp/postList";
import PostView from "../wp/postView";
import Search from "../wp/autocomplete";

import data from "../wp/_setup.json";
import Categories from "../../pages/Categories";
import CategoryList from "../wp/CategoryList";

// const lngs = {
//   en: { nativeName: "EN" },
//   lt: { nativeName: "LT" },
// };

i18n.init({ lng: "en" });

class RouterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isDataLoading: false,
      firstTime: false,
      amount: "",
    };
  }

  clearCart = () => {
    this.setState({ isDataLoading: true });
    if (this.state.isDataLoading) {
      UserService.cleanBarboraCart(this.state.data.id)
        .then(() => {
          this.setState({ isDataLoading: false });
          this.setState({
            amount: "Cleaned",
          });
          window.location.reload(true);
        })
        .catch((err) => {
          this.setState({ isDataLoading: false });
          console.log(err);
        });
    }
  };

  componentDidMount() {
    {
      process.env.REACT_APP_PRODUCT_LAUNCH === "true" &&
        fetchLoggedInUser()
          .then((response) => {
            this.setState({ data: response.data });
            this.setState({ isDataLoading: false });

            response.data.checkoutAmount &&
              this.setState({
                amount: /\d{1,2}[,.]{1}\d{1,2}/.exec(
                  response.data.checkoutAmount.replace(",", ".")
                ),
              });

            //SESSION USER STORAGE
            sessionStorage.setItem("userData", JSON.stringify(response.data));
          })
          .catch((e) => {
            // console.log("error", e);
            this.setState({ isDataLoading: false });
          });
    }
  }

  logOut = () => {
    localStorage.clear();
    // sessionStorage.clear();
  };

  render() {
    return (
      <div>
        {/* <nav className="p-3 border-gray-200 rounded bg-gray-50 dark:bg-gray-į00 dark:border-gray-700">
          <div className="container flex flex-wrap items-center justify-between mx-auto">
            <a href="/" className="flex items-center">
              <img alt="Kuky Website Logo" src={logo} width={160}></img>
            </a>
            <div className="flex md:order-2">
              {!this.state.data.username && (
                <a
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  href="/login"
                >
                  {i18n.t("navbar_login")}
                </a>
              )}
              {this.state.data.username && (
                <a
                  className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
                  href="/"
                  onClick={this.logOut}
                >
                  {i18n.t("navbar_logout")}
                </a>
              )}

              {Object.keys(lngs).map((lng) => (
                <span
                  key={lng}
                  style={{
                    fontWeight:
                      i18n.resolvedLanguage === lng ? "bold" : "normal",
                  }}
                  type="submit"
                  onClick={() => i18n.changeLanguage(lng)}
                >
                  {lngs[lng].nativeName}
                </span>
              ))}
            </div>
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
              id="navbar-cta"
            >
              <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-50 dark:border-gray-700">
                <li>
                  <a
                    href="/"
                    className="block py-2 pl-3 pr-4 text-black bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-black"
                    active={window.location.pathname === "/"}
                  >
                    {i18n.t("navbar_home")}
                  </a>
                </li>
                <li>
                  {this.state.data.id >= 1 &&
                    this.state.data.authorities &&
                    this.state.data.authorities.filter(
                      (value) => value.roleCode === "USER"
                    ).length > 0 && (
                      <Nav.Link
                        href="/fridge"
                        active={window.location.pathname === "/fridge"}
                      >
                        {i18n.t("navbar_pantry")}
                      </Nav.Link>
                    )}
                </li>
                <li>
                  <Trans i18nKey="navbar_recipe_search">
                    <a
                      href="/recipe-search"
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-black dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-black md:dark:hover:bg-transparent dark:border-gray-700"
                      active={window.location.pathname === "/recipe-search"}
                    >
                      Recipe Search
                    </a>
                  </Trans>
                </li>
                {this.state.data.id >= 1 &&
                  this.state.data.authorities &&
                  this.state.data.authorities.filter(
                    (value) => value.roleCode === "ADMIN"
                  ).length > 0 && (
                    <Nav.Link
                      href="/users"
                      active={window.location.pathname === "/users"}
                    >
                      {i18n.t("navbar_users")}
                    </Nav.Link>
                  )}
                <Nav variant="pills">
                  {this.state.data.checkoutAmount &&
                    this.state.data.cartId !== "" &&
                    this.state.amount !== "Cleaned" && (
                      <Nav.Link
                        eventKey="link-1"
                        href="https://barbora.lt/krepselis"
                        target="_blank"
                        disabled={
                          Number(this.state.amount) >= 0 && this.state.amount
                        }
                        active={
                          Number(this.state.amount) >= 0 && !this.state.amount
                        }
                      >
                        {Number(
                          this.state.data.cartId !== "" && this.state.amount
                        ) >= 0 &&
                          this.state.amount && (
                            <u>
                              <b>(€{this.state.amount} left until checkout)</b>
                            </u>
                          )}

                        {Number(this.state.amount) >= 0 &&
                          !this.state.amount && (
                            <u style={{ color: "white" }}>
                              <b>(Ready to checkout)</b>
                            </u>
                          )}
                      </Nav.Link>
                    )}
                  {this.state.data.checkoutAmount &&
                    this.state.data.cartId !== "" &&
                    this.state.amount !== "Cleaned" && (
                      <Nav.Link onClick={this.clearCart}>
                        <u>Clean Cart</u>
                      </Nav.Link>
                    )}
                </Nav>
              </ul>
            </div>
          </div>
        </nav> */}
        <Router>
          <Navbar
            expand="lg"
            className="shadow-sm bg-primary bg-opacity-10"
            id="navbar"
          >
            <Container>
              <Navbar.Brand href="/">
                <img
                  alt="Andrews Cookin Website Logo"
                  src={logo}
                  width={160}
                ></img>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav variant="tabs" className="me-auto">
                  {/* <Trans i18nKey="home"> */}
                  <Nav.Link href="/" active={window.location.pathname === "/"}>
                    {/* Example on how to use translation for simple text. Prefered */}
                    {i18n.t("navbar_home")}
                  </Nav.Link>
                  {/* </Trans> */}
                  {this.state.data.id >= 1 &&
                    this.state.data.authorities &&
                    this.state.data.authorities.filter(
                      (value) => value.roleCode === "USER"
                    ).length > 0 && (
                      <Nav.Link
                        href="/fridge"
                        active={window.location.pathname === "/fridge"}
                      >
                        {i18n.t("navbar_pantry")}
                      </Nav.Link>
                    )}
                  {/* Example on how to use translation for complex components */}
                  {process.env.REACT_APP_PRODUCT_LAUNCH === "true" && (
                    <Trans i18nKey="navbar_recipe_search">
                      <Nav.Link
                        href="/recipe-search"
                        active={window.location.pathname === "/recipe-search"}
                      >
                        Recipe Search
                      </Nav.Link>
                    </Trans>
                  )}
                  {process.env.REACT_APP_PRODUCT_LAUNCH === "true" && (
                    <Nav.Link
                      href="/#pricing"
                      active={window.location.pathname === "/home"}
                    >
                      Pricing
                    </Nav.Link>
                  )}
                  <Nav.Link
                    href={"/" + data.url.slug_posts}
                    active={
                      window.location.pathname === "/" + data.url.slug_posts
                    }
                  >
                    All Recipes
                  </Nav.Link>
                  <Nav.Link
                    href={
                      "/" + data.url.slug_posts + "/" + data.url.slug_categories
                    }
                    active={
                      window.location.pathname ===
                      "/" + data.url.slug_posts + "/" + data.url.slug_categories
                    }
                  >
                    Categories
                  </Nav.Link>
                  <Nav.Link
                    href="https://www.youtube.com/@AndrewsCookin"
                    target="_blank"
                    active={window.location.pathname === "/#"}
                  >
                    YouTube
                  </Nav.Link>

                  {/* {this.state.data.id >= 1 &&
                  this.state.data.authorities &&
                  this.state.data.authorities.filter(
                    (value) => value.roleCode === "USER"
                  ).length > 0 && (
                    <Nav.Link
                      href="/barbora"
                      active={window.location.pathname === "/barbora"}
                    >
                      "Barbora"
                    </Nav.Link>
                  )} */}
                  {this.state.data.id >= 1 &&
                    this.state.data.authorities &&
                    this.state.data.authorities.filter(
                      (value) => value.roleCode === "ADMIN"
                    ).length > 0 && (
                      <Nav.Link
                        href="/users"
                        active={window.location.pathname === "/users"}
                      >
                        {i18n.t("navbar_users")}
                      </Nav.Link>
                    )}
                </Nav>
                <Nav variant="pills">
                  {this.state.data.checkoutAmount &&
                    this.state.data.cartId !== "" &&
                    this.state.amount !== "Cleaned" && (
                      <Nav.Link
                        eventKey="link-1"
                        href="https://barbora.lt/krepselis"
                        target="_blank"
                        disabled={
                          Number(this.state.amount) >= 0 && this.state.amount
                        }
                        active={
                          Number(this.state.amount) >= 0 && !this.state.amount
                        }
                      >
                        {Number(
                          this.state.data.cartId !== "" && this.state.amount
                        ) >= 0 &&
                          this.state.amount && (
                            <u>
                              <b>(€{this.state.amount} left until checkout)</b>
                            </u>
                          )}

                        {Number(this.state.amount) >= 0 &&
                          !this.state.amount && (
                            <u style={{ color: "white" }}>
                              <b>(Ready to checkout)</b>
                            </u>
                          )}
                      </Nav.Link>
                    )}
                  {this.state.data.checkoutAmount &&
                    this.state.data.cartId !== "" &&
                    this.state.amount !== "Cleaned" && (
                      <Nav.Link onClick={this.clearCart}>
                        <u>Clean Cart</u>
                      </Nav.Link>
                    )}
                  {process.env.REACT_APP_PRODUCT_LAUNCH === "true" &&
                    (!this.state.data.username && (
                      <Nav.Link href="/login">
                        {i18n.t("navbar_login")}
                      </Nav.Link>
                    ),
                    this.state.data.username && (
                      <Nav.Link href="/" onClick={this.logOut}>
                        {i18n.t("navbar_logout")}
                      </Nav.Link>
                    ))}
                  {/* {process.env.REACT_APP_PRODUCT_LAUNCH === "true" && ( */}
                  <ThemeChanger removeNavDropdown={true} />
                  {/* )} */}

                  {/* //REMOVE LANGUAGE SUPPORT FOR NOW
                 {Object.keys(lngs).map((lng) => (
                  <Nav.Link
                    key={lng}
                    style={{
                      fontWeight:
                        i18n.resolvedLanguage === lng ? "bold" : "normal",
                    }}
                    type="submit"
                    onClick={() => i18n.changeLanguage(lng)}
                  >
                    {lngs[lng].nativeName}
                  </Nav.Link>
                ))} */}
                </Nav>
                <Nav className="post-search-bar">
                  <Search />
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          {/* Down below are WP navbar
          <Navbar collapseOnSelect className="px-3">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                {data.menu.map((route) => {
                  //console.log(route)
                  if (route.children !== undefined) {
                    return (
                      <Dropdown as={NavItem}>
                        <Dropdown.Toggle as={NavLink}>
                          {route.title}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {route.children.map((child) => (
                            <Dropdown.Item>
                              <Link to={child.path}>{child.title}</Link>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    );
                  } else {
                    return (
                      <Nav.Item>
                        {
                          <Link to={route.path} className="nav-link">
                            {route.title}
                          </Link>
                        }
                      </Nav.Item>
                    );
                  }
                })}
              </Nav>
              <Nav className="justify-content-end">
                <Search />
              </Nav>
            </Navbar.Collapse>
          </Navbar> */}
          <br />

          <Routes>
            <Route
              path="/recipe-search"
              element={<FoodSearch userData={this.state.data} />}
            ></Route>
            <Route
              path="/barbora"
              element={<Barbora userData={this.state.data} />}
            ></Route>
            <Route
              path="/fridge"
              element={<Fridge userData={this.state.data} />}
            ></Route>
            <Route
              path="/users"
              element={<Users userData={this.state.data} />}
            ></Route>
            <Route path="/login" element={<LoginComponent />}></Route>
            <Route
              exact
              path="/"
              element={
                <Home
                  user={this.state.data.username}
                  firstTime={this.state.firstTime}
                />
              }
            ></Route>
            <Route path="/*" element={<NotFoundPage />}></Route>
            <Route
              path="/terms-and-conditions"
              element={<Terms></Terms>}
            ></Route>
            <Route
              path="/privacy-conditions"
              element={<Privacy></Privacy>}
            ></Route>
            {/* Down are wp routes */}
            {/* Categories routes */}
            <Route
              path={"/" + data.url.slug_posts + "/" + data.url.slug_categories}
              element={<Categories />}
            ></Route>
            <Route
              path={
                "/" +
                data.url.slug_posts +
                "/" +
                data.url.slug_categories +
                "/:categoryName"
              }
              element={<CategoryList />}
            />

            {/* <Route element={<PostList />} path="/recipes" /> */}
            <Route
              path={"/" + data.url.slug_posts + "/"}
              element={<PostList />}
            />
            <Route
              path={"/" + data.url.slug_posts + "/:postName"}
              element={<PostView />}
            />
            {/* <Route path={"/:page"} element={<PageView />} /> */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </div>
    );
  }
}
export default RouterComponent;
