import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import FridgeService from "../fridge/service/FridgeService";
import i18n from "../../i18n";
import { Alert } from "reactstrap";

class RecipeDoneButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      isDataLoading: false,
      food: [...this.props.food1, ...this.props.food2],
      visible: false,
      visible2: false,
      cancel: false,
    };
    this.click = this.click.bind(this);
  }

  handleShow = () => this.setState({ show: true });
  handleCancel = () => {
    this.setState({ cancel: true });
    this.setState({ visible: false });
    this.setState({ visible2: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible2: false });
      }, 3000); //3 second timer for the succesfull cancel alert to close
    });
  };

  onShowAlert = () => {
    // this.handleClose();
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
        if (!this.state.cancel) {
          this.click();
        }
      }, 3000); //3 second timer for the alert to close
    });
  };

  click() {
    this.setState({ isDataLoading: true });

    FridgeService.updateFridgeFood(this.props.userId, this.state.food)
      .then((response) => {
        this.setState({ data: response.data, isDataLoading: false });
        // this.handleClose();
      })
      .catch((err) => {
        this.setState({ data: err, isDataLoading: false });
      });
  }

  render() {
    return (
      <span>
        {/* <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {i18n.t("recipeInfoCard_button_recipeDone_modal_title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{i18n.t("global_warning_rusure")}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              {i18n.t("global_close_button")}
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                this.onShowAlert();
              }}
            >
              {i18n.t("global_yes_button")}
            </Button>
          </Modal.Footer>
        </Modal> */}
        <button
          variant="outline-success"
          // onClick={this.handleShow}
          onClick={() => {
            this.onShowAlert();
          }}
          disabled={this.state.isDataLoading}
          className="mt-3 font-bold mt-2.5 bg-green-500 hover:bg-green-400 text-white font-base text-base py-2 px-2 border-b-4 border-green-700 hover:border-green-400 rounded m-auto"
        >
          {" "}
          {i18n.t("recipeInfoCard_button_recipeDone")}{" "}
        </button>
        {/* Success alert */}
        <Alert
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 999,
          }}
          color="success"
          isOpen={this.state.visible}
        >
          {i18n.t("recipeInfoCard_button_recipeDone_alert_body")}{" "}
          <a href="#" className="alert-link" onClick={this.handleCancel}>
            {i18n.t("global_link_here")}
          </a>
          !
        </Alert>
        {/* Canceled alert */}
        <Alert
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 999,
          }}
          color="danger"
          isOpen={this.state.visible2}
        >
          {i18n.t("recipeInfoCard_button_recipeDone_alert_body_cancel")}
        </Alert>
      </span>
    );
  }
}
export default RecipeDoneButton;
