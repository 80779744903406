import React, { Component } from "react";
import { wp } from "../api/WpApi";
import { Form, Row, Col, Button } from "react-bootstrap";

import Autocomplete from "./autocompleteResult";

class Search extends Component {
  state = {
    query: "",
    results: [],
  };

  getResult = () => {
    // Fetch pages with search value "xyz": http://wp-api.org/node-wpapi/using-the-client/#api-query-parameters
    wp.posts()
      .search(this.state.query)
      .then((posts) => {
        this.setState({
          isLoading: false,
          results: posts, // Get first element from array
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  };

  getResultingCategories = () => {
    // Fetch pages with search value "xyz": http://wp-api.org/node-wpapi/using-the-client/#api-query-parameters
    wp.categories()
      .search(this.state.query)
      .then((posts) => {
        this.setState({
          isLoading: false,
          results: posts, // Get first element from array
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  };

  handleInputChange = () => {
    this.setState(
      {
        query: this.search.value,
      },
      () => {
        if (this.state.query && this.state.query.length > 1) {
          this.getResult();
          this.search.setAttribute("aria-expanded", "true");
        } else {
          this.setState({
            results: [],
          });
          this.search.setAttribute("aria-expanded", "false");
        }
      }
    );
  };

  handleInputFocus = () => {
    document.getElementById("autocomplete-result").style.display = "block";
  };

  handleInputBlur = () => {
    setTimeout(function () {
      document.getElementById("autocomplete-result").style.display = "none";
    }, 500);
  };

  render() {
    return (
      <Form>
        <Col xs="auto">
          <Form.Control
            type="text"
            className="post-search-bar"
            placeholder="Search for a recipe..."
            ref={(input) => (this.search = input)}
            onChange={this.handleInputChange}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            role="combobox"
            aria-owns="autocomplete-result"
            aria-autocomplete="list"
            aria-expanded="false"
            aria-controls="autocomplete-result"
          />
          <Autocomplete results={this.state.results} />
        </Col>
      </Form>
    );
  }
}

export default Search;
