import React from "react";
import { Helmet } from "react-helmet";

class MetaTags extends React.Component {

    
    render() {
      return (
        <Helmet><meta name={this.props.name} content={this.props.content}></meta></Helmet>
      );
    
  }
}


export default MetaTags;